function ExcelIcon(props) {
  return (
    <svg
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.3359 0.000488281C15.5182 0.000488281 15.6745 0.0655924 15.8047 0.195801C15.9349 0.326009 16 0.482259 16 0.664551V13.3364C16 13.5187 15.9349 13.675 15.8047 13.8052C15.6745 13.9354 15.5182 14.0005 15.3359 14.0005H4.66406C4.48177 14.0005 4.32552 13.9354 4.19531 13.8052C4.0651 13.675 4 13.5187 4 13.3364V11.0005H0.664062C0.481771 11.0005 0.325521 10.9354 0.195312 10.8052C0.0651042 10.675 0 10.5187 0 10.3364V3.66455C0 3.48226 0.0651042 3.32601 0.195312 3.1958C0.325521 3.06559 0.481771 3.00049 0.664062 3.00049H4V0.664551C4 0.482259 4.0651 0.326009 4.19531 0.195801C4.32552 0.0655924 4.48177 0.000488281 4.66406 0.000488281H15.3359ZM4 7.85205L4.94531 9.62549H6.375L4.78906 7.04736L6.34375 4.51611H4.97656L4.10156 6.11768C4.09115 6.1333 4.08073 6.15153 4.07031 6.17236C4.0651 6.18799 4.05729 6.20622 4.04688 6.22705C3.90625 5.94059 3.76302 5.65674 3.61719 5.37549C3.47135 5.08903 3.32552 4.80257 3.17969 4.51611H1.72656L3.24219 7.06299L1.58594 9.62549H3.02344L4 7.85205ZM9.5 13.0005V11.0005H5V13.0005H9.5ZM9.5 10.0005V7.50049H8V10.0005H9.5ZM9.5 6.50049V4.00049H8V6.50049H9.5ZM9.5 3.00049V1.00049H5V3.00049H9.5ZM15 13.0005V11.0005H10.5V13.0005H15ZM15 10.0005V7.50049H10.5V10.0005H15ZM15 6.50049V4.00049H10.5V6.50049H15ZM15 3.00049V1.00049H10.5V3.00049H15Z"
        fill="#217346"
      />
    </svg>
  )
}

export default ExcelIcon
