import clsx from "clsx"

function Spinner({ className = "w-8 h-8", label = "Loading...", ...props }) {
  return (
    <div className="flex flex-col items-center justify-center">
      <div
        className={clsx(
          "inline-block animate-spin rounded-full border-[3px] border-current border-t-lochmara text-link-water",
          className
        )}
        role="status"
        aria-label="loading"
        {...props}
      >
        <span className="sr-only">Loading...</span>
      </div>
      {label ? (
        <span className="mt-2 text-xs text-lochmara">{label}</span>
      ) : null}
    </div>
  )
}

export default Spinner
