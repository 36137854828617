import { useSearchParams } from "react-router-dom"
import { useFormik } from "formik"
import { XCircleIcon, CheckCircleIcon } from "@heroicons/react/24/outline"
import api from "../utils/api"
import useMutation from "../hooks/useMutation"
import VerificationTokenForm from "../components/VerificationTokenForm"
import ReportView from "../components/ReportView"
import ReportButton from "../components/ReportButton"
import Page from "../components/Page"
import { useEffect, useState } from "react"
import ReportPopover from "../components/ReportPopover"
import Spinner from "../components/Spinner"
import { getErrorMessageStyles, getButtonStyles } from "../utils/styles"
import { isValidEmail } from "../utils/validations"
import logo from "../images/logo.png"

function Report() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [searchParams] = useSearchParams()
  const token = searchParams.get("token")
  const { data: report, mutate: prepareMedicalReport } = useMutation((body) =>
    api.post("/v1/prepareMedicalReport", body)
  )
  const [validationCode, setValidationCode] = useState(() =>
    searchParams.get("validationCode")
  )

  useEffect(() => {
    if (report) {
      document.title = `${report.reportName} | OneDigital Benchmarking`
    }
  }, [report])

  useEffect(() => {
    if (token && validationCode) {
      async function handleVerify() {
        try {
          setLoading(true)
          setError(null)
          await prepareMedicalReport({ validationCode, token })
        } catch (err) {
          setError(err.message)
        } finally {
          setLoading(false)
        }
      }
      handleVerify()
    }
  }, [token, validationCode, prepareMedicalReport])

  useEffect(() => {
    if (!token) setError("Token doesn't exists")
  }, [token])

  return (
    <Page className="bg-alabaster">
      <Header
        showActions={!!report}
        validationCode={validationCode}
        token={token}
      />
      {report ? (
        <ReportView report={report} />
      ) : (
        <div className="flex flex-1 items-center justify-center">
          {loading ? (
            <Spinner />
          ) : token ? (
            <VerificationTokenForm onVerify={setValidationCode} error={error} />
          ) : error ? (
            <ErrorMessage>{error}</ErrorMessage>
          ) : null}
        </div>
      )}
    </Page>
  )
}

function Header({ showActions, validationCode, token }) {
  return (
    <header className="flex h-[5.125rem] flex-col">
      <div className="mx-1 flex max-w-app flex-1 items-center justify-between md:mx-20 2xl:mx-auto 2xl:w-full">
        <img src={logo} alt="Logo" />
        {showActions ? (
          <div className="flex gap-x-4">
            <ExportPDFPopover validationCode={validationCode} token={token} />
            <ShareReportPopover validationCode={validationCode} />
          </div>
        ) : null}
      </div>
      <div className="h-2.5 bg-gradient-to-r from-allports to-sushi" />
    </header>
  )
}

function ShareReportPopover({ validationCode }) {
  const link = window.location.href
  return (
    <ReportPopover
      buttonProps={{ label: "SHARE", as: ReportButton }}
      panelClassName="-mr-1.5 translate-x-20 transform"
      title="Share report"
    >
      <div className="flex flex-col gap-y-6">
        <Input name="reportLink" label="Report Link" value={link} readOnly />
        <Input
          name="verificationToken"
          label="Verification Token"
          value={validationCode}
        />
      </div>
      <div className="mt-9">
        <ReportButton
          className="w-full"
          onClick={() =>
            navigator.clipboard.writeText(
              `Report link: ${link} - Verification token: ${validationCode}`
            )
          }
        >
          COPY DETAILS
        </ReportButton>
      </div>
    </ReportPopover>
  )
}

function ExportPDFPopover({ validationCode, token }) {
  return (
    <ReportPopover
      buttonProps={{
        label: "EXPORT TO PDF",
        as: ReportButton,
        variant: "outline",
      }}
      panelClassName="-mr-2.5 translate-x-36 transform"
      actionsClassName="justify-center"
      title="Export to PDF"
    >
      <ExportPDFPopoverView validationCode={validationCode} token={token} />
    </ReportPopover>
  )
}

function ExportPDFPopoverView({ validationCode, token }) {
  const {
    mutate: exportMedicalReport,
    isError: isErrorExportMedicalReport,
    error: errorExportMedicalReport,
  } = useMutation((body) => api.post("/v1/exportMedicalReport", body))
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      email: "",
    },
    validate: (values) => {
      const errors = {}

      if (!values.email) {
        errors.email = "Mandatory field"
      } else if (!isValidEmail(values.email)) {
        errors.email = "Invalid email"
      }

      return errors
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await exportMedicalReport({
          email: values.email,
          validationCode,
          token,
        })
        setIsFormSubmitted(true)
      } finally {
        setSubmitting(false)
      }
    },
  })

  if (isFormSubmitted) {
    return (
      <IconMessage
        type="error"
        icon={CheckCircleIcon}
        textColor="text-green-600"
      >
        You'll receive an email with the PDF soon
      </IconMessage>
    )
  }

  if (formik.isSubmitting) {
    return <Spinner label="Sending..." />
  }

  if (isErrorExportMedicalReport) {
    return (
      <IconMessage type="error" icon={XCircleIcon} textColor="text-red-600">
        {errorExportMedicalReport}
      </IconMessage>
    )
  }

  return (
    <>
      <div className="flex flex-col gap-y-6">
        <p className="text-sm">The PDF file will be sent to you via email.</p>
        <Input
          name="email"
          label="Email address"
          type="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.email}
        />
      </div>
      <div className="mt-9">
        <ReportButton className="w-full" onClick={formik.handleSubmit}>
          SEND PDF
        </ReportButton>
      </div>
    </>
  )
}

function IconMessage({ icon: Icon, textColor, children }) {
  return (
    <div className="flex items-center justify-center gap-x-2">
      <Icon className={`h-5 w-5 ${textColor}`} />
      <p className={`text-sm ${textColor}`}>{children}</p>
    </div>
  )
}

function Input({ label, name, error, ...props }) {
  return (
    <div className="flex flex-col">
      {label ? <label htmlFor={name}>{label}</label> : null}
      <input
        id={name}
        name={name}
        type="text"
        className={getButtonStyles({
          className: "mt-2 rounded border-pumice text-shark",
        })}
        {...props}
      />
      {error ? (
        <ErrorMessage className="mt-1.5 text-xs">{error}</ErrorMessage>
      ) : null}
    </div>
  )
}

function ErrorMessage({ children, className }) {
  return <span className={getErrorMessageStyles(className)}>{children}</span>
}

export default Report
