import { useState } from "react"
import { twMerge } from "tailwind-merge"
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline"
import Card from "./Card"

function InformationSection({
  title,
  children,
  disabled,
  rootClassName,
  contentClassName,
}) {
  const [open, setOpen] = useState(true)
  return (
    <section className={twMerge("space-y-5", rootClassName)}>
      {disabled ? (
        <span className={twMerge(HEADER_STYLES, "ml-10")}>{title}</span>
      ) : (
        <button
          className={twMerge(HEADER_STYLES, "space-x-5")}
          type="button"
          onClick={() => setOpen((v) => !v)}
        >
          {open ? (
            <ChevronDownIcon className="h-5 w-5" />
          ) : (
            <ChevronRightIcon className="h-5 w-5" />
          )}
          <span>{title}</span>
        </button>
      )}
      {open && children ? (
        typeof children === "function" ? (
          children()
        ) : (
          <Card
            className={twMerge("grid grid-cols-3 gap-x-16", contentClassName)}
          >
            {children}
          </Card>
        )
      ) : null}
    </section>
  )
}

const HEADER_STYLES = "flex items-center text-base font-semibold text-shark"

export default InformationSection
