import { twMerge } from "tailwind-merge"

function Subsection({
  title,
  children,
  containerClassName,
  titleClassName = "text-base",
}) {
  return (
    <div className={twMerge("flex flex-col space-y-5", containerClassName)}>
      {title ? (
        typeof title === "string" ? (
          <span
            className={twMerge("font-semibold text-tuatara", titleClassName)}
          >
            {title}
          </span>
        ) : (
          title
        )
      ) : null}
      {children}
    </div>
  )
}

export default Subsection
