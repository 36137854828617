import { Field } from "formik"
import Input from "./Input"

function FormInput({ name, error, ...props }) {
  return (
    <Field name={name}>
      {({ field, meta }) => (
        <Input
          {...props}
          {...field}
          value={meta.value ?? ""}
          error={error || (meta.touched ? meta.error : "")}
        />
      )}
    </Field>
  )
}

export default FormInput
