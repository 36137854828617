import { useState, useRef } from "react"
import { ArrowUpCircleIcon } from "@heroicons/react/24/outline"
import Button from "./Button"

function DragAndDropFiles({ id, inputProps, onChange }) {
  const [dragActive, setDragActive] = useState(false)
  const inputRef = useRef(null)

  const handleDrag = function (e) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true)
    } else if (e.type === "dragleave") {
      setDragActive(false)
    }
  }

  function handleFiles(files) {
    if (onChange && files && files.length > 0) {
      onChange(files)
    }
  }

  const handleDrop = function (e) {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    handleFiles(e.dataTransfer.files)
  }

  const handleChange = function (e) {
    e.preventDefault()
    handleFiles(e.target.files)
    // reset the input value to null
    e.target.value = null
  }

  return (
    <div className="relative h-48 w-80" onDragEnter={handleDrag}>
      <input
        {...inputProps}
        id={id}
        ref={inputRef}
        type="file"
        onChange={handleChange}
        className="hidden"
      />
      <label
        htmlFor={id}
        className={`flex h-full flex-col items-center justify-center gap-y-5 ${
          dragActive ? "bg-porcelain" : "bg-black-haze"
        } px-7 font-semibold`}
      >
        <ArrowUpIcon />
        <Text>
          {dragActive
            ? "Drop file to start upload"
            : "Drop your file here to upload it or select from your device"}
        </Text>
        {dragActive ? null : (
          <Button onClick={() => inputRef.current.click()}>Select files</Button>
        )}
      </label>
      {dragActive ? (
        <div
          className="absolute inset-0 flex flex-col items-center justify-center gap-y-5"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
        />
      ) : null}
    </div>
  )
}

function Text({ children }) {
  return (
    <p className="text-center text-sm font-semibold text-woodsmoke">
      {children}
    </p>
  )
}

function ArrowUpIcon() {
  return <ArrowUpCircleIcon className="h-8 w-8 text-woodsmoke" />
}

export default DragAndDropFiles
