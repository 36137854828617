import { useCallback, useRef, useState, useEffect } from "react"

export const STATES = {
  IDLE: "idle",
  LOADING: "loading",
  ERROR: "error",
}

function useMutation(query, initData, options = { initOnMount: false }) {
  const [state, setState] = useState(STATES.IDLE)
  const [data, setData] = useState(initData)
  const [error, setError] = useState(null)
  const queryRef = useRef(query)
  const mutate = useCallback(async (body) => {
    setState(STATES.LOADING)
    try {
      const data = await queryRef.current(body)
      setData(data)
      setState(STATES.IDLE)
      return data
    } catch (err) {
      setState(STATES.ERROR)
      setError(err.message)
      throw err
    }
  }, [])

  useEffect(
    function loadOnMountEffect() {
      if (options.initOnMount) {
        mutate()
      }
    },
    [mutate, options.initOnMount]
  )

  const isLoading = state === STATES.LOADING
  const isError = state === STATES.ERROR

  return { mutate, isLoading, isError, data, error, setData, setState }
}

export default useMutation
