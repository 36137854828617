import { useState } from "react"
import { read, utils } from "xlsx"
import { InformationCircleIcon, TrashIcon } from "@heroicons/react/24/outline"
import camelCase from "lodash/camelCase"
import Card from "./Card"
import InformationSection from "./InformationSection"
import DragAndDropFiles from "./DragAndDropFiles"
import InfoModal from "./InfoModal"
import ExcelIcon from "./icons/ExcelIcon"
import { PUBLIC_FOLDER } from "../constants/env"
import {
  validateEnrolledPlan,
  validateStatus,
  validateYearOfCensus,
  validateGender,
  validateRelationshipStatus,
  validateEnrollmentTier,
  validateZipCode,
  validateSalary,
  validateDate,
} from "../utils/validators"
import { getCensusFileYears } from "../utils/census"

const VALID_MIME_TYPES = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
]
function ReportAddCensusFiles({ onChange, census }) {
  const [error, setError] = useState(null)
  const [showValidationErrors, setShowValidationErrors] = useState(false)
  const [validationErrors, setValidationErrors] = useState(null)

  async function handleOnChange(files) {
    const file = files[0]
    if (!VALID_MIME_TYPES.includes(file.type)) {
      setError("Invalid file type")
      return
    }
    setError(null)
    onChange(null)
    setValidationErrors(null)

    try {
      const data = await readFile(file)
      let readData = read(data, { type: "binary" })
      const wsname = readData.SheetNames[0]
      if (readData.SheetNames.length > 1) {
        setError("Only one sheet allowed")
        return
      }
      const ws = readData.Sheets[wsname]
      const rawData = utils.sheet_to_json(ws, { header: 1, raw: false })
      let header = rawData.shift()
      const parsedHeader = header.reduce((acc, header, index) => {
        const column = header.toLowerCase()
        const camelCaseColumn = camelCase(column)
        if (validators[column]) {
          acc.push({ header, column, camelCaseColumn, index })
        }
        return acc
      }, [])

      // check if file has the proper amount of columns
      const validatorKeys = Object.keys(validators)
      if (parsedHeader.length !== validatorKeys.length) {
        const missingHeaders = validatorKeys.reduce((acc, column) => {
          if (!parsedHeader.find((d) => d.column === column)) {
            acc.push(column)
          }
          return acc
        }, [])
        setError(
          `Missing column${
            missingHeaders.length > 1 ? "s" : ""
          }: ${missingHeaders.join(", ")}`
        )
        return
      }

      const errorsMap = new Map()
      const parsedData = rawData.map((row, rowIndex) =>
        parsedHeader.reduce((acc, d) => {
          const cell = row[d.index]
          const validation = validators[d.column](cell)
          if (validation.error) {
            const key = `${d.column}.${validation.error}`
            const headerErrors = errorsMap.get(key) ?? {
              header: d.header,
              rows: [],
              error: validation.error,
            }
            headerErrors.rows.push(rowIndex + 2) // in excel index starts in 1 and 1 more for the header
            errorsMap.set(key, headerErrors)
          }
          acc[d.camelCaseColumn] = validation.cell
          return acc
        }, {})
      )

      if (errorsMap.size > 0) {
        setShowValidationErrors(true)
        const errors = Array.from(errorsMap.values())
        errors.sort((a, b) => a.header.localeCompare(b.header))
        setValidationErrors(errors)
        setError("Invalid data in file")
        onChange(null)
      } else {
        setShowValidationErrors(false)
        setValidationErrors(null)
        setError(null)
        onChange({
          name: file.name,
          years: getCensusFileYears(parsedData),
          data: parsedData,
        })
      }
    } catch (e) {
      setError("Error reading file")
    }
  }

  return (
    <InformationSection title="Add Census Files">
      {() => (
        <>
          <Card className="flex flex-col gap-y-6">
            <p className="text-sm">
              To add Census Files, please download and use our{" "}
              <CensusFileDownload />. Make sure all data is complete and in the
              correct format.
            </p>
            <div className="flex items-center justify-center">
              <DragAndDropFiles
                id="census-files"
                inputProps={{
                  multiple: false,
                  accept: ".xls,.xlsx",
                }}
                onChange={handleOnChange}
              />
            </div>
            <div className="flex flex-col self-start">
              {census ? (
                <div className="flex items-center justify-between gap-x-10 self-start bg-black-haze py-2 px-3">
                  <div className="flex items-center gap-x-3">
                    <div className="h-3">
                      <ExcelIcon className="h-3" />
                    </div>
                    <span className="text-sm text-tuatara">{census.name}</span>
                    <span className="text-xs text-abbey">{census.years}</span>
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      setValidationErrors(null)
                      setError(null)
                      onChange(null)
                    }}
                  >
                    <TrashIcon className="h-4 w-4" />
                  </button>
                </div>
              ) : null}
              {error ? (
                <div className="mt-1.5 flex items-center gap-x-2.5 text-red-600">
                  <div className="h-4 w-4">
                    <InformationCircleIcon className="h-4 w-4" />
                  </div>
                  <p className="text-sm">
                    {error}.{" "}
                    {validationErrors ? (
                      <button
                        className="text-allports"
                        type="button"
                        onClick={() => setShowValidationErrors(true)}
                      >
                        View errors lists
                      </button>
                    ) : (
                      <>
                        To prevent future errors, please download and use our{" "}
                        <CensusFileDownload />.
                      </>
                    )}{" "}
                  </p>
                </div>
              ) : null}
            </div>
          </Card>
          <InfoModal
            show={showValidationErrors}
            title="We encountered the following errors on your Census File, please correct them and retry again."
            classNamePanel="max-w-5xl max-h-[90vh] overflow-y-auto"
            onClose={() => setShowValidationErrors(false)}
          >
            {validationErrors ? (
              <table>
                <thead>
                  <tr className="border-b border-black-haze">
                    <TableHeader>Error</TableHeader>
                    <TableHeader>Column</TableHeader>
                    <TableHeader>Row(s)</TableHeader>
                  </tr>
                </thead>
                <tbody>
                  {validationErrors.map(({ error, header, rows }, index) => (
                    <tr
                      key={index}
                      className="border-b border-black-haze text-sm font-normal text-abbey"
                    >
                      <td className="w-full py-4 pr-16 align-baseline">
                        {VALIDATION_ERROR_MESSAGES[error]}
                      </td>
                      <td className="py-4 pr-16 align-baseline">
                        <div className="whitespace-nowrap">{header}</div>
                      </td>
                      <td className="py-4">
                        <div className="w-24">{rows.join(", ", "and")}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : null}
          </InfoModal>
        </>
      )}
    </InformationSection>
  )
}

function TableHeader({ children }) {
  return (
    <th className="py-4 text-sm font-semibold text-woodsmoke">{children}</th>
  )
}

function CensusFileDownload() {
  return (
    <a
      className="text-allports"
      href={PUBLIC_FOLDER + "/census-file-template.xlsx"}
      download
    >
      Census File Template
    </a>
  )
}

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onloadend = (e) => resolve(e.target.result)
    reader.readAsBinaryString(file)
  })
}

const validators = {
  "year of census": validateYearOfCensus,
  "date of birth": validateDate,
  gender: validateGender,
  "relationship status": validateRelationshipStatus,
  status: validateStatus,
  "enrollment tier": validateEnrollmentTier,
  "enrolled plan": validateEnrolledPlan,
  "hire date": validateDate,
  "zip code": validateZipCode,
  salary: validateSalary,
}

function ErrorHighlight({ children }) {
  return <span className="font-medium text-tuatara">{children}</span>
}
const VALIDATION_ERROR_MESSAGES = {
  required: "Missing data",
  census_format: (
    <p>
      Year of Census must be <ErrorHighlight>YYYY</ErrorHighlight> or{" "}
      <ErrorHighlight>YY</ErrorHighlight>
    </p>
  ),
  date_format: (
    <p>
      Date must be <ErrorHighlight>MM/DD/YYYY</ErrorHighlight>,{" "}
      <ErrorHighlight>M/D/YY</ErrorHighlight>,{" "}
      <ErrorHighlight>M/DD/YY</ErrorHighlight>,{" "}
      <ErrorHighlight>MM/D/YY</ErrorHighlight>,{" "}
      <ErrorHighlight>M/D/YYYY</ErrorHighlight>,{" "}
      <ErrorHighlight>M/DD/YY</ErrorHighlight>,{" "}
      <ErrorHighlight>YY/M/D</ErrorHighlight>,{" "}
      <ErrorHighlight>YY/M/DD</ErrorHighlight>,{" "}
      <ErrorHighlight>YY/MM/D</ErrorHighlight>,{" "}
      <ErrorHighlight>YYYY/MM/D</ErrorHighlight>,{" "}
      <ErrorHighlight>YYYY/M/DD</ErrorHighlight> or{" "}
      <ErrorHighlight>YYYY/MM/D</ErrorHighlight>
    </p>
  ),
  gender: (
    <p>
      Gender must be <ErrorHighlight>Male</ErrorHighlight>,{" "}
      <ErrorHighlight>Female</ErrorHighlight>,{" "}
      <ErrorHighlight>Non-Binary</ErrorHighlight>,{" "}
      <ErrorHighlight>Undeclared</ErrorHighlight>,{" "}
      <ErrorHighlight>Not Available</ErrorHighlight>,{" "}
      <ErrorHighlight>M</ErrorHighlight> or <ErrorHighlight>F</ErrorHighlight>
    </p>
  ),
  relationshipStatus: (
    <p>
      Relationship Status must be <ErrorHighlight>Employee</ErrorHighlight>,{" "}
      <ErrorHighlight>Spouse</ErrorHighlight>,{" "}
      <ErrorHighlight>Dependent</ErrorHighlight>,{" "}
      <ErrorHighlight>Disabled</ErrorHighlight>{" "}
      <ErrorHighlight>Dependent</ErrorHighlight>, or{" "}
      <ErrorHighlight>Subscriber</ErrorHighlight>
    </p>
  ),
  status: (
    <p>
      Status must be <ErrorHighlight>Active</ErrorHighlight>,{" "}
      <ErrorHighlight>COBRA</ErrorHighlight>,{" "}
      <ErrorHighlight>Leave</ErrorHighlight> or{" "}
      <ErrorHighlight>Waived</ErrorHighlight>
    </p>
  ),
  enrollmentTier: (
    <p>
      Enrollment tier must be <ErrorHighlight>Employee Only</ErrorHighlight>,{" "}
      <ErrorHighlight>Employee and Spouse</ErrorHighlight>,{" "}
      <ErrorHighlight>Employee and Child(ren)</ErrorHighlight>,{" "}
      <ErrorHighlight>EE</ErrorHighlight>,{" "}
      <ErrorHighlight>EE Only</ErrorHighlight>,{" "}
      <ErrorHighlight>EEOnly</ErrorHighlight>,{" "}
      <ErrorHighlight>ES</ErrorHighlight>, <ErrorHighlight>E+S</ErrorHighlight>,{" "}
      <ErrorHighlight>EC</ErrorHighlight>, <ErrorHighlight>E+H</ErrorHighlight>,{" "}
      <ErrorHighlight>E+CH</ErrorHighlight>,{" "}
      <ErrorHighlight>E+F</ErrorHighlight>, <ErrorHighlight>FAM</ErrorHighlight>{" "}
      or <ErrorHighlight>NA</ErrorHighlight>
    </p>
  ),
  zipCode: (
    <p>
      Zip Code must be <ErrorHighlight>XXXXX</ErrorHighlight>,{" "}
      <ErrorHighlight>XXXXX-XXXX</ErrorHighlight> or{" "}
      <ErrorHighlight>XXXXX XXXX</ErrorHighlight>
    </p>
  ),
  salary: (
    <p>
      Salary must be <ErrorHighlight>XXX,XXX,XXX</ErrorHighlight>,{" "}
      <ErrorHighlight></ErrorHighlight>,{" "}
      <ErrorHighlight>$XXX,XXX,XXX.XX</ErrorHighlight> or{" "}
      <ErrorHighlight>XXX,XXX,XXX.XX</ErrorHighlight>
    </p>
  ),
}

export default ReportAddCensusFiles
