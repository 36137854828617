import { useFormikContext } from "formik"
import InformationSection from "./InformationSection"
import Subsection from "./Subsection"
import FormPlanInput from "./FormPlanInput"
import { PLAN_TYPES } from "../constants/plan"

function MedicalPlanDesign() {
  const { values } = useFormikContext()
  const currentPlanType = values.plans[values.selectedPlan]?.type

  return (
    <InformationSection title="Medical Plan Design" contentClassName="gap-y-12">
      <div className="space-y-12">
        <Subsection title="In-Network Tier Deductible">
          <FormPlanInput
            name="medicalPlanDesign.inNetworkTierDeductible.individual"
            label="Individual"
          />
          <FormPlanInput
            name="medicalPlanDesign.inNetworkTierDeductible.family"
            label="Family"
          />
        </Subsection>
        <Subsection title="Copayment">
          <FormPlanInput
            name="medicalPlanDesign.copayment.primaryCarePhysician"
            label="Primary Care Physician"
            disabledForTypes={[PLAN_TYPES.HDHP]}
          />
          <FormPlanInput
            name="medicalPlanDesign.copayment.emergencyRoom"
            label="Emergency Room"
            disabledForTypes={[PLAN_TYPES.HDHP]}
          />
          <FormPlanInput
            name="medicalPlanDesign.copayment.specialPhysician"
            label="Specialist Physician"
            disabledForTypes={[PLAN_TYPES.HDHP]}
          />
          <FormPlanInput
            name="medicalPlanDesign.copayment.inpatientHospitalStay"
            label="Inpatient Hospital Stay"
            disabledForTypes={[PLAN_TYPES.PPO, PLAN_TYPES.HDHP]}
          />
        </Subsection>
      </div>
      <Subsection title="In-Network Tier Out-Of-Pocket Maximum">
        <FormPlanInput
          name="medicalPlanDesign.inNetworkTierOutOfPocketMax.individual"
          label="Individual"
          disabledForTypes={[PLAN_TYPES.HMO]}
        />
        <FormPlanInput
          name="medicalPlanDesign.inNetworkTierOutOfPocketMax.family"
          label="Family"
          disabledForTypes={[PLAN_TYPES.HMO]}
        />
        {currentPlanType === PLAN_TYPES.PPO &&
        values.benchmarkSource === "2022" ? (
          <span className="text-xs text-shark">
            Since the Mercer 2022 data set does not include information for the
            PPO Out-of-Pocket Max, the Mercer 2021 data will be used for PPO
            Out-of-Pocket Max.
          </span>
        ) : null}
      </Subsection>
      <Subsection title="Coinsurance">
        <FormPlanInput
          type="percentage"
          name="medicalPlanDesign.coinsurance.coinsurance"
          label="Coinsurance"
          disabledForTypes={[PLAN_TYPES.HMO]}
        />
        <FormPlanInput
          type="percentage"
          name="medicalPlanDesign.coinsurance.inPatientHospitalStay"
          label="Inpatient Hospital Stay Coinsurance"
          disabledForTypes={[PLAN_TYPES.HDHP]}
        />
      </Subsection>
    </InformationSection>
  )
}

export default MedicalPlanDesign
