function Checkbox({ name, label, ...props }) {
  return (
    <div className="flex items-center space-x-2.5">
      <input
        className="text-allports hover:text-orient disabled:border-none disabled:bg-ebb"
        id={name}
        name={name}
        {...props}
        type="checkbox"
      />
      <label htmlFor={name} name={name} className="text-sm font-normal">
        {label}
      </label>
    </div>
  )
}

export default Checkbox
