import { Dialog } from "@headlessui/react"
import { InformationCircleIcon, XMarkIcon } from "@heroicons/react/24/outline"
import Modal from "./Modal"
import clsx from "clsx"

function InfoModal({
  title,
  show,
  onClose,
  afterLeave,
  children,
  classNamePanel,
}) {
  return (
    <Modal
      show={show}
      onClose={onClose}
      classNamePanel={clsx("relative", classNamePanel)}
      afterLeave={afterLeave}
    >
      <button className="absolute right-4 top-6 mt-0.5" onClick={onClose}>
        <XMarkIcon className="h-5 w-8" />
      </button>
      <Dialog.Title
        as="h3"
        className="mr-6 mb-5 flex items-center gap-x-2.5 font-semibold leading-6 text-tuatara"
      >
        <div className="h-5 w-5">
          <InformationCircleIcon className="h-5 w-5" />
        </div>
        <span>{title}</span>
      </Dialog.Title>
      {children}
    </Modal>
  )
}

export default InfoModal
