import { Fragment } from "react"
import { Popover, Transition } from "@headlessui/react"
import { twMerge } from "tailwind-merge"

function ReportPopover({ buttonProps, panelClassName, title, children }) {
  return (
    <div className="max-w-sm">
      <Popover className="relative">
        <Popover.Button {...buttonProps}>{buttonProps.label}</Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel
            unmount
            className={twMerge(
              "absolute right-full z-20 mt-3 w-screen max-w-xs sm:max-w-lg",
              panelClassName
            )}
          >
            <div className="rounded-lg bg-white p-6 text-left align-middle shadow-xl">
              {title ? (
                <h4 className="mb-6 text-center text-2xl font-extrabold text-shark">
                  {title}
                </h4>
              ) : null}
              {children}
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  )
}

export default ReportPopover
