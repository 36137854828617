import InformationSection from "./InformationSection"
import Card from "./Card"
import FormPlanInput from "./FormPlanInput"

function ClientInformation() {
  return (
    <InformationSection title="Rx Plan Design">
      {() => (
        <Card className="mt-5 space-y-10">
          <div className="grid grid-cols-3 gap-x-16">
            <FormPlanInput name="rxPlanDesign.genericRx" label="Generic Rx" />

            <div className="space-y-5">
              <FormPlanInput
                name="rxPlanDesign.brandNameFormularyRx"
                label="Brand Name Formulary Rx"
              />
              <FormPlanInput
                name="rxPlanDesign.brandNameNonFormularyRx"
                label="Brand Name Non-Formulary Rx"
              />
            </div>

            <FormPlanInput
              name="rxPlanDesign.specialtyBiotechRx"
              label="Specialty/Biotech Rx"
            />
          </div>
        </Card>
      )}
    </InformationSection>
  )
}

export default ClientInformation
