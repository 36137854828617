import { twMerge } from "tailwind-merge"

function Card({ children, className }) {
  return (
    <div
      className={twMerge(
        "border border-gray-nurse p-5 shadow-[0px_0.6px_1.8px_rgba(0,_0,_0,_0.1),_0px_3.2px_7.2px_rgba(0,_0,_0,_0.13)]",
        className
      )}
    >
      {children}
    </div>
  )
}

export default Card
