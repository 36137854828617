import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import {PLAN_TYPES} from "./constants/plan"

import "react-tooltip/dist/react-tooltip.css"
import "./index.css"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>
)

class D365Data {
  listeners = []
  data = null

  getData(fn) {
    if (this.data) {
      fn(this.data)
    } else {
      this.listeners.push(fn)
    }
  }

  setData(data) {
    this.data = data
    for (let listener; !!listener; listener = listener.shift()) {
      listener(data)
    }
  }
}

window.d365Data = new D365Data()
window.addEventListener("message", (event) => {
  if (isDomoEverywhereIframeSizeChangeEvent(event)) {
    updateIframeHeightFromEvent(event)
  } else if (isD365DataEvent(event)) {
    try {
      parseD365Event(event)
    } catch (e) {
      console.warn(e)
    }
  }
})

function isDomoEverywhereIframeSizeChangeEvent(event) {
  return event.data?.method === "notifyFrameSize"
}

function updateIframeHeightFromEvent(event) {
  const currentIframe = Array.from(
    document.getElementsByTagName("iframe")
  ).find((iframe) => iframe.contentWindow === event.source)
  if (currentIframe) {
    currentIframe.height = event.data.params.height
  }
}

function isD365DataEvent(event) {
  return event.data["Account Id"]
}

function parseD365Event(event) {
  console.log(event)
  let planInfo = []
  const rootItem = event.data
  let company = {
    name: rootItem["Name"],
    state: rootItem["State Name"],
    numberOfEmployees: rootItem["Number of Employees"],
    industryCode: rootItem["Industry Code"].id,
    accountId: rootItem["Account Id"],
  }

  let metadata = {
    partnerName: rootItem["Partner"]?.name,
    partnerId: rootItem["Partner"]?.id,
    userName: rootItem["User"]?.name,
    userId: rootItem["User"]?.userid,
    appId: rootItem["App"]?.id,
    appName: rootItem["App"]?.name,
  }

  rootItem.Products.forEach((item) => {
    let planType = resolvePlanType(item["Product Plan Type"] || "")
    if (planType == null) {
      return
    }
    let planName = item["Name"]
    let plan = {
      name: planName,
      type: planType,
      simulated: null,
      medicalPlanCost: {
        monthlyTierContribution: {
          individual: null,
          family: null,
        },
        monthlyTierContributionPercentage: {
          individual: null,
          family: null,
        },
        monthlyPremium: {
          individual: null,
          family: null,
        },
        actuarial: {
          actuarialValues: null,
        },
        employerHsaContribution: {
          individual: null,
          family: null,
        },
      },
      medicalPlanDesign: {
        inNetworkTierDeductible: {
          individual: null,
          family: null,
        },
        inNetworkTierOutOfPocketMax: {
          individual: null,
          family: null,
        },
        coinsurance: {
          coinsurance: null,
          inPatientHospitalStay: null,
        },
        copayment: {
          primaryCarePhysician: null,
          specialPhysician: null,
          emergencyRoom: null,
          inpatientHospitalStay: null,
        },
      },
      rxPlanDesign: {
        genericRx: null,
        brandNameFormularyRx: null,
        brandNameNonFormularyRx: null,
        specialtyBiotechRx: null,
      },
    }
    plan.showPlanOnReport = true

    item.PlanDetails.forEach((planDetailsElement) => {
      let inNetworkValue = floatOrNull(
        cleanupValue(planDetailsElement["Innetwork"])
      )
      if (inNetworkValue === null) {
        return
      }
      let name = planDetailsElement["Name"]
      let category = planDetailsElement["Category"]

      if (
        (name === "Emergency Room" ||
          name === "Emergency Room Facility (Copay Waived if Admitted)") &&
        planType !== PLAN_TYPES.HDHP
      ) {
        plan.medicalPlanDesign.copayment.emergencyRoom = inNetworkValue
      }
      if (category === "Deductible" && name === "Individual") {
        plan.medicalPlanDesign.inNetworkTierDeductible.individual =
          inNetworkValue
      }
      if (category === "Deductible" && name === "Family") {
        plan.medicalPlanDesign.inNetworkTierDeductible.family = inNetworkValue
      }
      if (
        (category === "Out-of-Pocket Maximum(Including Deductible)" ||
          category === "Out-of-Pocket Maximum (Including Deductible)") &&
        planType !== PLAN_TYPES.HMO
      ) {
        if (name === "Individual") {
          plan.medicalPlanDesign.inNetworkTierOutOfPocketMax.individual =
            inNetworkValue
        }
        if (name === "Family") {
          plan.medicalPlanDesign.inNetworkTierOutOfPocketMax.family =
            inNetworkValue
        }
      }
      if (name === "Coinsurance" && planType !== PLAN_TYPES.HMO) {
        plan.medicalPlanDesign.coinsurance.coinsurance = inNetworkValue
      }
      if (name === "Inpatient Services - Hospital Facility") {
        if (planType !== PLAN_TYPES.HDHP) {
          plan.medicalPlanDesign.coinsurance.inPatientHospitalStay =
            inNetworkValue
        }

        if (planType === PLAN_TYPES.HMO) {
          plan.medicalPlanDesign.copayment.inpatientHospitalStay =
            inNetworkValue
        }
      }
      if (
        (name === "Physician Copay" || name === "Physician Visit") &&
        category === "Office Visit" &&
        planType !== PLAN_TYPES.HDHP
      ) {
        plan.medicalPlanDesign.copayment.primaryCarePhysician = inNetworkValue
      }
      if (
        (name === "Specialist Copay" || name === "Specialist Visit") &&
        planType !== PLAN_TYPES.HDHP
      ) {
        plan.medicalPlanDesign.copayment.specialPhysician = inNetworkValue
      }
      if (name === "Tier 1" && category === "Prescription Drugs") {
        plan.rxPlanDesign.genericRx = inNetworkValue
      }
      if (name === "Tier 2" && category === "Prescription Drugs") {
        plan.rxPlanDesign.brandNameFormularyRx = inNetworkValue
      }
      if (name === "Tier 3" && category === "Prescription Drugs") {
        plan.rxPlanDesign.brandNameNonFormularyRx = inNetworkValue
      }
      if (name === "Tier 4" && category === "Prescription Drugs") {
        plan.rxPlanDesign.specialtyBiotechRx = inNetworkValue
      }
    })

    item.PlanRates.forEach((planRateItem) => {
      let rateMoney = floatOrNull(
        cleanupValue(planRateItem["Employee Rate Money"])
      )
      let ratePercent = floatOrNull(
        cleanupValue(planRateItem["Employee Rate Percent"])
      )
      let monthlyPremium = floatOrNull(
        cleanupValue(planRateItem["Monthly Premium"])
      )

      switch (planRateItem["Rate Type"]) {
        case "Tiered":
          switch (planRateItem["Tier Type"]) {
            case "Employee":
            case "Employee Only":
              plan.medicalPlanCost.monthlyPremium.individual = monthlyPremium
              break
            case "Family":
              plan.medicalPlanCost.monthlyPremium.family = monthlyPremium
              break
            default:
              break
          }
          break
        case "Contribution":
          switch (planRateItem["Tier Type"]) {
            case "Employee":
            case "Employee Only":
              if (rateMoney) {
                plan.medicalPlanCost.monthlyTierContribution.individual =
                  rateMoney
              } else if (ratePercent) {
                plan.medicalPlanCost.monthlyTierContributionPercentage.individual =
                  ratePercent
              }
              break
            case "Family":
              if (rateMoney) {
                plan.medicalPlanCost.monthlyTierContribution.family = rateMoney
              } else if (ratePercent) {
                plan.medicalPlanCost.monthlyTierContributionPercentage.family =
                  ratePercent
              }
              break
            default:
              break
          }
          break
        default:
          break
      }
    })
    planInfo.push(plan)
  })

  window.d365Data.setData({
    company,
    plans: planInfo,
    metadata: metadata,
  })
}

function resolvePlanType(planType) {
  switch (planType) {
    case "PPO":
    case "POS":
      return PLAN_TYPES.PPO
    case "High Deductible Health Plan":
    case "HSA (Health Savings Account)":
      return PLAN_TYPES.HDHP
    case "HMO":
    case "EPO":
      return PLAN_TYPES.HMO
    default:
      return null
  }
}

function cleanupValue(val) {
  return val && val.replace(/[$%\n,]/g, "")
}

function floatOrNull(val) {
  let result = null
  if (val) {
    const parsedVal = parseFloat(val)
    if (!isNaN(parsedVal)) {
      result = parsedVal
    }
  }
  return result
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
