import { useMemo, useRef } from "react"
import { Field, useFormikContext } from "formik"
import set from "lodash/set"
import cloneDeep from "lodash/cloneDeep"
import FormSwitch from "./FormSwitch"
import Switch from "./Switch"
import Card from "./Card"
import InformationSection from "./InformationSection"
import InfoTooltip from "./InfoTooltip"
import MultiSelectPopover from "./MultiSelectPopover"
import { PLAN_TYPES } from "../constants/plan"

function ReportSettings({
  disabledAddAutomaticInsight,
  isDemographic,
  isMedical,
}) {
  return (
    <InformationSection title="Report Customizations">
      {() => (
        <Card>
          <div className="grid grid-cols-4 gap-x-16 gap-y-11">
            <div className="col-span-4 flex flex-col gap-y-4">
              {isMedical ? (
                <InfoTooltip
                  id="graph-table-selection"
                  as="span"
                  className="text-sm font-semibold text-tuatara"
                  title="Graph Selection"
                  label="A table will be created on the final report for every selected chip. Each selection will make the corresponding fields become mandatory."
                />
              ) : null}
              <div className="grid grid-cols-3 gap-x-16">
                {isMedical ? (
                  <>
                    <div className="flex flex-col gap-y-5">
                      <GraphSelectionPopover
                        planType={PLAN_TYPES.PPO}
                        title="PPO/POS Plan"
                        disabledGraphs={["employerHsaContribution"]}
                      />
                      <GraphSelectionPopover
                        planType={PLAN_TYPES.HMO}
                        title="HMO Plan"
                        disabledGraphs={[
                          "employerHsaContribution",
                          "inNetworkTierOutOfPocketMax",
                        ]}
                      />
                      <GraphSelectionPopover
                        planType={PLAN_TYPES.HDHP}
                        title="HDHP Plan"
                        disabledGraphs={["copayment"]}
                      />
                    </div>
                  </>
                ) : null}
                {isDemographic ? <DemographicChartsSelection /> : null}
              </div>
            </div>
            {isMedical ? (
              <div className="col-span-4 flex gap-x-11">
                <Field name="benchmarkDataOnly" type="checkbox">
                  {({ field, form: { values, setValues } }) => (
                    <Switch
                      label="Benchmark Data Only"
                      checked={field.checked}
                      tooltipProps={{
                        id: "benchmark-data-only",
                        label:
                          "Create a report using only benchmarking information. This option disables Automatic Insights functionality.",
                      }}
                      onChange={(val) => {
                        const updatedValues = {
                          ...values,
                          benchmarkDataOnly: val,
                        }
                        if (val) {
                          updatedValues.addAutomaticInsight = false
                          updatedValues.selectedPlan = ""
                        }
                        setValues(updatedValues)
                      }}
                      onBlur={field.onBlur}
                    />
                  )}
                </Field>
                <FormSwitch
                  name="addAutomaticInsight"
                  label="Automatic insights"
                  disabled={disabledAddAutomaticInsight}
                />
              </div>
            ) : null}
          </div>
        </Card>
      )}
    </InformationSection>
  )
}

function useHandleSelectAll(data) {
  const { values, setValues } = useFormikContext()

  function handleSelectAll(e) {
    const { checked } = e.target
    const valuesCopy = cloneDeep(values)
    data.forEach(
      ({ name, disabled }) => !disabled && set(valuesCopy, name, checked)
    )
    setValues(valuesCopy)
  }

  return [values, handleSelectAll]
}

function DemographicChartsSelection() {
  const data = [
    {
      type: "checkbox",
      name: "reportParts.demographic.generationalBreakout",
      label: "Generational Breakout",
      disabled: false,
    },
    {
      type: "checkbox",
      name: "reportParts.demographic.overview",
      label: "Overview",
      disabled: false,
    },
    {
      type: "checkbox",
      name: "reportParts.demographic.migration",
      label: "Migration",
      disabled: false,
    },
    {
      type: "checkbox",
      name: "reportParts.demographic.populationTable",
      label: "Population Table",
      disabled: false,
    },
  ]

  const [values, handleSelectAll] = useHandleSelectAll(data)
  const demographic = values.reportParts.demographic
  const { totalOptions, selectedOptions } = useMemo(() => {
    const demographicCopy = { ...demographic }
    const allValues = Object.values(demographicCopy)
    const selectedOptions = allValues.filter(Boolean).length

    return {
      totalOptions: allValues.length,
      selectedOptions,
    }
  }, [demographic])

  return (
    <MultiSelectPopover
      data={data}
      title="Demographic Charts"
      tooltip="All charts selected will be part of the final report."
      tooltipId="demographic-charts-selection"
      selectedOptionsAmount={selectedOptions}
      totalOptionsAmount={totalOptions}
      onSelectAll={handleSelectAll}
      dataTypeSubtitle="chart"
    />
  )
}

function GraphSelectionPopover({ planType, title, disabledGraphs }) {
  const disabledGraphsRef = useRef(disabledGraphs)
  const data = useMemo(() => {
    const graphs = [
      {
        type: "subtitle",
        label: "Tables",
      },
      {
        type: "checkbox",
        name: "tableMedicalPlanCost",
        label: "Medical Plan Cost",
      },
      {
        type: "checkbox",
        name: "tableMedicalPlanDesign",
        label: "Medical Plan Design",
      },
      {
        type: "checkbox",
        name: "tableRxPlanDesign",
        label: "Rx Plan Design",
      },
      {
        type: "subtitle",
        label: "Graphs",
      },
      {
        type: "checkbox",
        name: "monthlyTierContribution",
        label: "Employee Monthly Tier Contribution ($)",
      },
      {
        type: "checkbox",
        name: "monthlyTierContributionPercentage",
        label: "Employee Monthly Tier Contribution (%)",
      },
      {
        type: "checkbox",
        name: "monthlyPremium",
        label: "Monthly Premium",
      },
      {
        type: "checkbox",
        name: "employerHsaContribution",
        label: "Employer HSA Contribution",
      },
      {
        type: "checkbox",
        name: "actuarial",
        label: "Actuarial Values",
      },
      {
        type: "checkbox",
        name: "inNetworkTierDeductible",
        label: "In-Network Tier Deductible",
      },
      {
        type: "checkbox",
        name: "inNetworkTierOutOfPocketMax",
        label: "In-Network Tier Out-Of-Pocket Max.",
      },
      {
        type: "checkbox",
        name: "coinsurance",
        label: "Coinsurance",
      },
      {
        type: "checkbox",
        name: "copayment",
        label: "Copayment",
      },
      {
        type: "checkbox",
        name: "rxPlanDesign",
        label: "Rx Plan Design Graph ",
      },
    ]

    return graphs.map((g) =>
      g.type === "checkbox"
        ? {
            name: `reportParts.${planType}.${g.name}`,
            label: g.label,
            disabled: disabledGraphsRef.current.includes(g.name),
            type: g.type,
          }
        : g
    )
  }, [planType])

  const [values, handleSelectAll] = useHandleSelectAll(data)
  const reportPartsByPlanType = values.reportParts[planType]
  const { totalOptions, selectedOptions } = useMemo(() => {
    const reportPartsByPlanTypeCopy = { ...reportPartsByPlanType }

    disabledGraphsRef.current.forEach((g) => {
      delete reportPartsByPlanTypeCopy[g]
    })
    const allValues = Object.values(reportPartsByPlanTypeCopy)
    const selectedOptions = allValues.filter(Boolean).length

    return {
      totalOptions: allValues.length,
      selectedOptions,
    }
  }, [reportPartsByPlanType])

  return (
    <MultiSelectPopover
      data={data}
      title={title}
      selectedOptionsAmount={selectedOptions}
      totalOptionsAmount={totalOptions}
      onSelectAll={handleSelectAll}
      dataTypeSubtitle="graph"
    />
  )
}

export default ReportSettings
