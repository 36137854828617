import { useField } from "formik"
import Chip from "./Chip"

function FormRadioChip({ name, value, ...props }) {
  // eslint-disable-next-line no-unused-vars
  const [_, meta, helpers] = useField(name)
  return (
    <Chip
      value={meta.value === value}
      onChange={() => helpers.setValue(value)}
      {...props}
    />
  )
}

export default FormRadioChip
