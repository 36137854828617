import { useState } from "react"
import { useFormikContext, useField } from "formik"
import InformationSection from "./InformationSection"
import Select from "./Select"
import FormPlanInput from "./FormPlanInput"
import AddPlanModal from "./AddPlanModal"
import FormPlanType from "./FormPlanType"
import FormSwitch from "./FormSwitch"
import FormRadio from "./FormRadio"
import ErrorInput from "./ErrorInput"
import InfoTooltip from "./InfoTooltip"
import { getLabelStyles } from "../utils/styles"

function PlanInformation({ onAddPlan }) {
  const [openAddPlanModal, setOpenAddPlanModal] = useState(false)
  const { values, setFieldValue } = useFormikContext()
  const disabled = values.selectedPlan === ""
  const namePrefix = `plans.${values.selectedPlan}`
  return (
    <>
      <InformationSection
        title="Plan Information"
        rootClassName="sticky top-0 pt-2 bg-white z-10"
        contentClassName="gap-y-5"
        disabled
      >
        <Select
          label="Plan"
          name="plan"
          value={values.selectedPlan}
          disabled={values.benchmarkDataOnly}
          onChange={(e) => {
            const { value } = e.target
            if (value === "add") {
              setOpenAddPlanModal(true)
            } else {
              setFieldValue("selectedPlan", value, false)
            }
          }}
        >
          <option disabled value="">
            Select plan
          </option>
          <option value="add">Add new plan</option>
          {values.plans.map((p, i) => (
            <option key={i} value={i}>
              {p.name}
            </option>
          ))}
        </Select>
        <FormPlanInput type="text" name="name" label="Plan Name" />
        <FormPlanType name={`${namePrefix}.type`} />
        <SimulatedCheckboxes namePrefix={namePrefix} disabled={disabled} />
        <div>
          <FormSwitch
            name={`${namePrefix}.showPlanOnReport`}
            label="Show plan on report"
            disabled={disabled}
          />
        </div>
      </InformationSection>

      <AddPlanModal
        show={openAddPlanModal}
        onConfirm={(newPlan) => {
          onAddPlan(newPlan)
          setFieldValue("selectedPlan", values.plans.length, false)
        }}
        onClose={() => setOpenAddPlanModal(false)}
      />
    </>
  )
}

function SimulatedCheckboxes({ namePrefix, disabled }) {
  const name = `${namePrefix}.simulated`
  // eslint-disable-next-line no-unused-vars
  const [_, meta] = useField(name)
  const error = meta.touched ? meta.error : ""
  return (
    <div>
      <InfoTooltip
        id="simulated-checkboxes"
        className={getLabelStyles()}
        iconClassName="inline"
        title="Are the plan designs, contributions and rates below:"
        label={
          <>
            An existing medical plan is a plan that is currently offered by the
            employer to their employees. This means that no adjustments have
            been made to the existing plan’s plan design, contribution and
            rates. <br />
            <br /> A proposed medical plan is a plan that is currently not
            offered by the employer to their employees. This might also include
            adjustments that are being proposed to an existing plan’s plan
            design, contribution and rates.
          </>
        }
      />
      <div className="flex gap-x-10">
        <FormRadio
          name={name}
          label="Existing"
          value="existing"
          disabled={disabled}
          data-test="form-plan-simulated-radio"
        />
        <FormRadio
          name={name}
          label="Proposed"
          value="proposed"
          disabled={disabled}
          data-test="form-plan-simulated-radio"
        />
      </div>
      {error ? <ErrorInput>{error}</ErrorInput> : null}
    </div>
  )
}

export default PlanInformation
