import Label from "./Label"
import { getButtonStyles } from "../utils/styles"
import InputContainer from "./InputContainer"
import ErrorInput from "./ErrorInput"

function Select({
  name,
  label,
  containerClassName,
  className,
  error,
  ...props
}) {
  return (
    <InputContainer className={containerClassName}>
      <Label htmlFor={name}>{label}</Label>
      <select
        id={name}
        name={name}
        className={getButtonStyles({
          className: className,
          showError: !!error,
        })}
        {...props}
      />
      <ErrorInput>{error}</ErrorInput>
    </InputContainer>
  )
}

export default Select
