import Label from "./Label"
import Modal from "./Modal"
import Button from "./Button"
import { cloneElement } from "react"

function ReportGeneratedModal({ report, onClose }) {
  return (
    <Modal
      title="Your report was successfully generated."
      show={report != null}
      onClose={onClose}
    >
      <div className="mt-7 space-y-7">
        <CopyInfo label="Report Link" copyButton={<Button>Copy link</Button>}>
          {report?.reportUrl}
        </CopyInfo>
        <CopyInfo
          label="Verification Token"
          copyButton={<Button>Copy token</Button>}
        >
          {report?.validationCode}
        </CopyInfo>
      </div>
    </Modal>
  )
}

function CopyInfo({ label, copyButton, children }) {
  const button = cloneElement(copyButton, {
    onClick: () => navigator.clipboard.writeText(children),
  })
  return (
    <div>
      <Label>{label}</Label>
      <div className="flex gap-x-3">
        <input readOnly type="text" value={children || ""} />
        {button}
      </div>
    </div>
  )
}

export default ReportGeneratedModal
