const RetirementIcon = (props) => (
  <svg
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_1484_15061"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="48"
      height="48"
    >
      <rect width="48" height="48" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1484_15061)">
      <path
        d="M32 21.55C32.4 21.55 32.7583 21.3917 33.075 21.075C33.3917 20.7583 33.55 20.4 33.55 20C33.55 19.6 33.3917 19.2417 33.075 18.925C32.7583 18.6083 32.4 18.45 32 18.45C31.6 18.45 31.2417 18.6083 30.925 18.925C30.6083 19.2417 30.45 19.6 30.45 20C30.45 20.4 30.6083 20.7583 30.925 21.075C31.2417 21.3917 31.6 21.55 32 21.55ZM16 16.55H26V15H16V16.55ZM10.5 40C9.53333 36.5 8.54167 33.0333 7.525 29.6C6.50833 26.1667 6 22.6333 6 19C6 16.5 6.875 14.375 8.625 12.625C10.375 10.875 12.5 10 15 10H26C26.8667 8.86667 27.8917 7.91667 29.075 7.15C30.2583 6.38333 31.5667 6 33 6C33.2667 6 33.5 6.1 33.7 6.3C33.9 6.5 34 6.73333 34 7C34 7.06667 33.9917 7.13333 33.975 7.2C33.9583 7.26667 33.9333 7.33333 33.9 7.4C33.6667 8 33.475 8.61667 33.325 9.25C33.175 9.88333 33.05 10.5167 32.95 11.15L38.8 17H42V27.5L36.75 29.2L33.5 40H26V36H18V40H10.5ZM11.65 38.45H16.45V34.45H27.55V38.45H32.35L35.5 28.1L40.45 26.35V18.55H38.15L31.45 11.85C31.45 11.0833 31.5167 10.35 31.65 9.65C31.7833 8.95 31.9667 8.26667 32.2 7.6C31.1333 7.9 30.1417 8.38333 29.225 9.05C28.3083 9.71667 27.5833 10.55 27.05 11.55H15C12.9333 11.55 11.175 12.275 9.725 13.725C8.275 15.175 7.55 16.9333 7.55 19C7.55 22.3667 8.00833 25.6417 8.925 28.825C9.84167 32.0083 10.75 35.2167 11.65 38.45Z"
        fill="white"
      />
    </g>
  </svg>
)

export default RetirementIcon
