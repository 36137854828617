import { API_URI } from "../constants/env"

const COMMON_HEADERS = {
  "content-type": "application/json;charset=UTF-8",
}

class Api {
  baseRequest(url, options) {
    const token = sessionStorage.getItem("token")
    return fetch(`${API_URI}${url}`, {
      headers: {
        ...COMMON_HEADERS,
        ...options.headers,
        ...(token ? { Authorization: `Bearer ${token}` } : null),
      },
      body: options.data ? JSON.stringify(options.data) : undefined,
      ...options,
    })
      .catch((err) => {
        console.error(err)
        throwError()
      })
      .then(async (r) => {
        if (r.ok) {
          try {
            const res = await r.json()
            return res
          } catch (err) {
            return null
          }
        }

        let response = null
        try {
          response = (await r.json()).errorMessage
        } catch (err) {
          throwError()
        }
        throwError(response)
      })
  }

  post(url, data, options) {
    return this.baseRequest(url, {
      ...options,
      method: "POST",
      data,
    })
  }
}

function throwError(message) {
  throw new Error(
    message || "We have encountered an issue, please try again later!"
  )
}

export default new Api()
