import { Link as RouterLink } from "react-router-dom"

const Link = ({ isExternal, to, children, ...rest }) => {
  return isExternal ? (
    <a href={to} target="_blank" rel="noopener noreferrer" {...rest}>
      {children}
    </a>
  ) : (
    <RouterLink to={to} {...rest}>
      {children}
    </RouterLink>
  )
}

export default Link
