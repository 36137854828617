import { forwardRef } from "react"
import { Link } from "react-router-dom"
import { twMerge } from "tailwind-merge"

const GenericButton = forwardRef(
  ({ type = "button", className, to, children, ...rest }, ref) => {
    const variantClassName = twMerge(
      "inline-flex justify-center border px-4 py-2 text-sm font-semibold focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:border-transparent",
      className
    )

    return to ? (
      <Link ref={ref} className={variantClassName} to={to}>
        {children}
      </Link>
    ) : (
      <button ref={ref} className={variantClassName} type={type} {...rest}>
        {children}
      </button>
    )
  }
)

export default GenericButton
