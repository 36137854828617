import startCase from "lodash/startCase"

function validateEnrolledPlan(cell) {
  if (typeof cell !== "string") {
    return { error: "required", cell }
  }
  return { cell }
}
function validateStatus(cell) {
  if (typeof cell !== "string") {
    return { error: "required", cell }
  }
  const lowerCaseCell = cell.toLowerCase()
  if (["active", "cobra", "leave", "waived"].includes(lowerCaseCell)) {
    return { cell: startCase(lowerCaseCell) }
  }
  return { error: "status", cell }
}
function validateYearOfCensus(cell) {
  if (typeof cell !== "string") {
    return { error: "required", cell }
  }
  if (Number.isNaN(+cell)) {
    return { error: "census_format", cell }
  }
  if (cell.length === 2) {
    return { cell: +cell.padStart(4, "20") }
  }
  if (cell.length !== 4) {
    return { error: "census_format", cell }
  }
  return { cell: +cell }
}
function validateGender(cell) {
  if (typeof cell !== "string") {
    return { error: "required", cell }
  }
  const lowerCaseCell = cell.toLowerCase()
  if (
    ["male", "female", "other", "undeclared", "not available"].includes(
      lowerCaseCell
    )
  ) {
    return { cell: startCase(lowerCaseCell) }
  }
  if (lowerCaseCell === "non-binary") {
    return { cell: "Non-Binary" }
  }
  if (lowerCaseCell === "m") {
    return { cell: "Male" }
  }
  if (lowerCaseCell === "f") {
    return { cell: "Female" }
  }
  return { error: "gender", cell }
}
function validateRelationshipStatus(cell) {
  if (typeof cell !== "string") {
    return { error: "required", cell }
  }
  const lowerCaseCell = cell.toLowerCase()
  if (
    ["employee", "spouse", "dependent", "disabled dependent"].includes(
      lowerCaseCell
    )
  ) {
    return { cell: startCase(lowerCaseCell) }
  }
  if (lowerCaseCell === "subscriber") {
    return { cell: "Employee" }
  }
  return { error: "relationshipStatus", cell }
}
function validateEnrollmentTier(cell) {
  if (typeof cell !== "string") {
    return { error: "required", cell }
  }
  const lowerCaseCell = cell.toLowerCase()
  if (["employee only", "not applicable"].includes(lowerCaseCell)) {
    return { cell: startCase(lowerCaseCell) }
  }

  if (lowerCaseCell === "employee and spouse") {
    return { cell: "Employee and Spouse" }
  }
  if (lowerCaseCell === "employee and child(ren)") {
    return { cell: "Employee and Child(ren)" }
  }
  if (lowerCaseCell === "employee and family") {
    return { cell: "Employee and Family" }
  }
  if (["ee", "ee only", "eeonly"].includes(lowerCaseCell)) {
    return { cell: "Employee Only" }
  }
  if (["es", "e+s"].includes(lowerCaseCell)) {
    return { cell: "Employee and Spouse" }
  }
  if (["ec", "e+c", "e+ch"].includes(lowerCaseCell)) {
    return { cell: "Employee and Child(ren)" }
  }
  if (["e+f", "fam"].includes(lowerCaseCell)) {
    return { cell: "Employee and Family" }
  }
  if ("na" === lowerCaseCell) {
    return { cell: "Not Applicable" }
  }
  return { error: "enrollmentTier", cell }
}
function validateZipCode(cell) {
  if (typeof cell !== "string") {
    return { error: "required", cell }
  }
  const [zipCode] = cell.split(/[-\s]/)
  if (zipCode.length === 5) {
    return { cell: zipCode }
  }
  return { error: "zipCode", cell }
}
function validateSalary(cell) {
  if (typeof cell !== "string") {
    return { error: "required", cell }
  }
  const tokens = cell.split("$")
  const strNumber = tokens.at(-1)
  const currency = Number(strNumber.replaceAll(",", ""))
  if (Number.isNaN(currency)) {
    return { error: "salary", cell }
  }
  return { cell: currency }
}

function validateDate(cell) {
  if (typeof cell !== "string") {
    return { error: "required", cell }
  }
  const invalidDateError = { error: "date_format", cell }
  if (!/\d{1,4}\/\d{1,2}\/\d{1,4}$/.test(cell)) {
    return invalidDateError
  }
  const tokens = cell.split("/")
  const token0 = +tokens[0]
  const token1 = +tokens[1]
  const token2 = +tokens[2]
  if (Number.isNaN(token0) || Number.isNaN(token1) || Number.isNaN(token2)) {
    return invalidDateError
  }
  let day = null,
    month = null,
    year = null
  // check if month is in the first position
  if (tokens[2].length === 4) {
    year = token2
    month = token0
    day = token1
    // check if month is in the first position
  } else if (tokens[0].length === 4) {
    year = token0
    month = token1
    day = token2
  }
  if (
    !(
      Number.isInteger(year) &&
      Number.isInteger(month) &&
      Number.isInteger(day)
    )
  ) {
    return invalidDateError
  }
  if (month < 1 || month > 12) {
    return { error: "date_format", cell }
  }
  if (day < 1 || day > 31) {
    return { error: "date_format", cell }
  }
  return { cell: `${padZeroes(month)}/${padZeroes(day)}/${year}` }
}

function padZeroes(str) {
  return String(str).padStart(2, "0")
}

export {
  validateEnrolledPlan,
  validateStatus,
  validateYearOfCensus,
  validateGender,
  validateRelationshipStatus,
  validateEnrollmentTier,
  validateZipCode,
  validateSalary,
  validateDate,
}
