import clsx from "clsx"

function Page({ className, children, error, ...props }) {
  return (
    <main
      className={clsx("mx-auto flex min-h-screen flex-col", className)}
      {...props}
    >
      {children}
    </main>
  )
}

export default Page
