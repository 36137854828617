import { Field } from "formik"
import TextArea from "./TextArea"

function FormTextArea({ name, ...props }) {
  return (
    <Field name={name}>
      {({ field, meta }) => (
        <TextArea
          {...field}
          error={meta.touched ? meta.error : ""}
          {...props}
        />
      )}
    </Field>
  )
}

export default FormTextArea
