function Radio({ name, label, ...props }) {
  return (
    <label
      name={name}
      className="flex items-center gap-x-2.5 text-sm font-normal"
    >
      <input
        className="text-allports hover:text-orient disabled:border-none disabled:bg-ebb"
        name={name}
        {...props}
        type="radio"
      />
      {label}
    </label>
  )
}

export default Radio
