import { useState } from "react"
import ReactCurrencyInput from "react-currency-input-field"
import Label from "./Label"
import InputContainer from "./InputContainer"
import ErrorInput from "./ErrorInput"
import { getButtonStyles } from "../utils/styles"

function CurrencyInput({ name, value, label, error, onValueChange, ...props }) {
  const [internalValues, setInternalValues] = useState({
    value: `${value}`,
    float: value,
  })
  const intervalValue =
    internalValues.float !== value ? value : internalValues.value
  return (
    <InputContainer>
      {label ? <Label htmlFor={name}>{label}</Label> : null}
      <ReactCurrencyInput
        className={getButtonStyles({
          showError: !!error,
        })}
        name={name}
        value={intervalValue}
        onValueChange={(_, __, values) => {
          setInternalValues(values)
          onValueChange(values.float)
        }}
        {...props}
      />
      <ErrorInput>{error}</ErrorInput>
    </InputContainer>
  )
}

export default CurrencyInput
