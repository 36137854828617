import { useMemo } from "react"
import { Tab } from "@headlessui/react"
import { twMerge } from "tailwind-merge"
import banner from "../images/banner.jpg"

const MAX_WIDTH_STYLES = "mx-auto max-w-[75.25rem]"

function ReportView({ report }) {
  const showDemographic = useMemo(
    () =>
      report.demographic
        ? Object.values(report.demographic).some((value) => !!value)
        : false,
    [report.demographic]
  )

  return (
    <div className="w-full space-y-14">
      <div
        className="relative mx-auto flex h-80 max-w-app items-center bg-cover bg-center bg-no-repeat px-20"
        style={{ backgroundImage: `url(${banner})` }}
        data-test="banner"
      >
        <div className="absolute inset-0 bg-shark opacity-60"></div>
        <h1 className="relative z-10 text-4xl text-white">
          <span className="font-bold">
            {report.companyName}
            {" - "}
          </span>
          {report.reportName}
        </h1>
      </div>

      {report.executiveSummary ? (
        <IFrame
          title="Executive Summary"
          {...report.executiveSummary}
          className="mx-auto max-w-app"
          height="300px"
          data-test="executive-summary"
        />
      ) : null}

      {showDemographic ? (
        <div>
          <H2 data-test="demographics-playbook-title">Demographics Playbook</H2>
          <div className="mx-auto max-w-app">
            <Section
              title="Generational Breakout"
              prefix="demographic"
              titleClassName="my-0"
              section={
                report.demographic.generationalBreakout && [
                  report.demographic.generationalBreakout,
                ]
              }
            />
            <Section
              title="Overview"
              prefix="demographic"
              section={
                report.demographic.overview && [report.demographic.overview]
              }
            />
            <Section
              title="Migration"
              prefix="demographic"
              section={
                report.demographic.migration && [report.demographic.migration]
              }
            />
            <Section
              title="Population Table"
              prefix="demographic"
              section={
                report.demographic.populationTable && [
                  report.demographic.populationTable,
                ]
              }
            />
          </div>
        </div>
      ) : null}

      {report.embedPlans.length > 0 ? (
        <>
          <H2 data-test="benchmarking-playbook-title">
            Medical & Rx Benchmarking Playbook
          </H2>
          <Tab.Group>
            <Tab.List
              className={twMerge(MAX_WIDTH_STYLES, "text-sm text-shark")}
            >
              {report.embedPlans.map(({ planName }, i) => (
                <StyledTab key={i} prefix={planName.toLowerCase()}>
                  {planName}
                </StyledTab>
              ))}
            </Tab.List>
            <Tab.Panels className="mx-auto mt-12 max-w-app">
              {report.embedPlans.map(
                (
                  {
                    planName,
                    automaticInsight,
                    tableSection,
                    medicalPlanCostSection,
                    medicalPlanDesignSection,
                    rxPlanDesignSection,
                  },
                  i
                ) => {
                  const prefix = planName.toLowerCase()

                  return (
                    <Tab.Panel key={i} unmount={false}>
                      {automaticInsight ? (
                        <IFrame
                          title={`automatic insight.${i}`}
                          key={i}
                          data-test={`${prefix}-tabs-automatic-insight`}
                          {...automaticInsight}
                        />
                      ) : null}
                      <Section section={tableSection} prefix={prefix} />
                      {tableSection?.length > 0 ? (
                        <div className={`mt-6 space-y-3 ${MAX_WIDTH_STYLES}`}>
                          <Legend
                            colorNameClassName="bg-[#E5833B]"
                            colorName="ORANGE"
                          >
                            The data point is<Bold>leaner</Bold>than the
                            benchmark
                          </Legend>
                          <Legend
                            colorNameClassName="bg-[#33769E]"
                            colorName="BLUE"
                          >
                            The data point is<Bold>in line</Bold>with the
                            benchmark
                          </Legend>
                          <Legend
                            colorNameClassName="bg-[#97B84C]"
                            colorName="GREEN"
                          >
                            The data point is<Bold>richer</Bold>than the
                            benchmark
                          </Legend>
                        </div>
                      ) : null}
                      <Section
                        title="Medical Plan Cost"
                        prefix={prefix}
                        section={medicalPlanCostSection}
                      />
                      <Section
                        title="Medical Plan Design"
                        prefix={prefix}
                        section={medicalPlanDesignSection}
                      />
                      <Section
                        title="Rx Plan Design"
                        prefix={prefix}
                        section={rxPlanDesignSection}
                      />
                    </Tab.Panel>
                  )
                }
              )}
            </Tab.Panels>
          </Tab.Group>
        </>
      ) : null}

      <Footer />
    </div>
  )
}

function H2({ children, ...rest }) {
  return (
    <h2
      className="bg-prussian-blue py-5 text-center align-middle text-2xl font-semibold text-white"
      {...rest}
    >
      {children}
    </h2>
  )
}

function H3({ children, className, ...rest }) {
  return (
    <h3
      className={twMerge(
        "my-16 max-w-xl bg-gradient-to-r from-allports to-sushi py-4 pl-12 text-2xl font-semibold text-white xl:pl-20",
        className
      )}
      {...rest}
    >
      {children}
    </h3>
  )
}

function StyledTab({ children, prefix, ...props }) {
  return (
    <Tab
      {...props}
      data-test="tabs-button"
      data-prefix={prefix}
      className={({ selected }) =>
        twMerge(
          "w-32 whitespace-nowrap py-3",
          selected ? "border-b-2 border-allports" : "border-b border-gray-nurse"
        )
      }
    >
      {children}
    </Tab>
  )
}

function Section({ title, section, prefix, titleClassName }) {
  if (!section) {
    return null
  }

  return (
    <>
      {title ? (
        <H3 data-test={`${prefix}-tabs-title`} className={titleClassName}>
          {title}
        </H3>
      ) : null}
      <div className={MAX_WIDTH_STYLES}>
        {section.map((section, i) => (
          <IFrame
            data-test={`${prefix}-${title ? "tabs-widget" : "tabs-tables"}`}
            title={`${title}.${i}`}
            key={i}
            {...section}
          />
        ))}
      </div>
    </>
  )
}

function IFrame({ url, token, title, ...props }) {
  return (
    <iframe
      width="100%"
      height="600px"
      title={title}
      {...props}
      srcDoc={`
      <html>
        <body>
            Loading
            <form id="form" action="${url}" method="post">
              <input type="hidden" name="embedToken" value="${token}">
            </form>
            <script>
              document.getElementById("form").submit();
            </script>
        </body>
      </html>`}
    />
  )
}

const currentYear = new Date().getFullYear()
function Footer() {
  return (
    <footer className="bg-scorpion py-6 px-7">
      <p className="mx-auto max-w-app text-xs text-pumice">
        Copyright © {currentYear} OneDigital.
        <br />
        <br />
        OneDigital’s benchmarks, studies and reports are made available the
        express understanding that it will be used solely for information
        purposes. The data contained therein may not be reproduced or used for
        any other purpose not authorized by OneDigital. The material contained
        in benchmarks, studies, and reports should not be construed or relied
        upon as accounting, legal, regulatory, tax, research, business or
        investment advice. The material contained in the benchmarks, studies and
        reports does not constitute a recommendation to take any action
        identified or contemplated in the benchmark, studies, or reports.
        <br />
        <br />
        Neither OneDigital nor any of its agents, representatives, advisers,
        affiliates, directors, officers or employees accept any responsibility
        or liability for or make any representation or warranty (either express
        or implied) as to the truth, accuracy, reliability or completeness of
        the information contained in the benchmark, studies, reports or any
        other information made available in connection with benchmarking.
        OneDigital has no obligation to provide the users of the benchmark with
        additional information, to update the information contained therein or
        to correct any inaccuracies which may become apparent.
      </p>
    </footer>
  )
}

function Legend({ colorName, colorNameClassName, children }) {
  return (
    <div className="flex">
      <span
        className={twMerge(
          "flex w-24 items-center justify-center py-2 text-xs font-semibold text-white",
          colorNameClassName
        )}
      >
        {colorName}
      </span>
      <p className="flex w-72 items-center justify-center bg-black-haze py-2 text-xs text-woodsmoke">
        {children}
      </p>
    </div>
  )
}

function Bold({ children }) {
  return <span className="font-semibold">&nbsp;{children}&nbsp;</span>
}

export default ReportView
