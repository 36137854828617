import { useEffect, useState } from "react"
import update from "lodash/update"
import Select from "./Select"
import Checkbox from "./Checkbox"
import useMutation from "../hooks/useMutation"
import api from "../utils/api"
import { PERCENTAGE_FIELDS, REPORT_PARTS } from "../constants/plan"
import { getCensusFileYears } from "../utils/census"

function PreviousReports({
  accountId,
  reports,
  setValues,
  categories: formCategories,
}) {
  const [checked, setChecked] = useState(false)
  const [reportId, setReportId] = useState("")
  const filteredReports = reports.filter((r) => r.accountId === accountId)

  const { mutate: restoreMedicalReport } = useMutation((body) =>
    api.post("/v1/restoreMedicalReport", body)
  )
  useEffect(() => {
    if (!reportId) {
      return
    }

    restoreMedicalReport({ id: +reportId }).then(
      ({
        plans: plansResponse,
        categories: categoriesResponse,
        census: censusResponse,
        reportParts: reportPartsResponse,
        ...response
      }) => {
        const plans = plansResponse.map((plan) => {
          update(plan, "type", (v) => v.toLowerCase())
          update(plan, "simulated", (v) =>
            typeof v === "string" ? (v ? "proposed" : "existing") : null
          )
          plan.showPlanOnReport = true
          PERCENTAGE_FIELDS.forEach((field) =>
            update(plan, field, (val) => val && val * 100)
          )
          return plan
        })

        const categories = Object.entries(formCategories).reduce(
          (acc, [key, value]) => {
            acc[key] = [...value]
            categoriesResponse[key].forEach((c) => {
              const val = acc[key].find((o) => o.name === c)
              if (val) {
                val.selected = true
              }
            })
            return acc
          },
          {}
        )

        const isMedical = plansResponse?.length > 0
        const isDemographic = censusResponse?.length > 0
        const playbookSelection =
          isMedical && isDemographic
            ? "both"
            : isDemographic
            ? "demographic"
            : "medical"

        let census = null
        if (isDemographic) {
          census = {
            name: "Census file",
            years: getCensusFileYears(censusResponse),
            data: censusResponse,
          }
        }

        const reportParts = {
          ...REPORT_PARTS,
          ...reportPartsResponse,
        }

        setValues({
          ...response,
          plans,
          categories,
          reportParts,
          benchmarkDataOnly: plans.length === 0,
          showExecutiveSummary: !!response.executiveSummary,
          playbookSelection,
          census,
        })
      }
    )
  }, [reportId, formCategories, setValues, restoreMedicalReport])

  return (
    <div className="max-w-xs space-y-5">
      <Checkbox
        name="viewPreviousBenchmarkReport"
        label="View a previous benchmark report"
        onChange={(e) => setChecked(e.target.checked)}
        checked={checked}
      />
      {checked ? (
        <Select
          label="Benchmark reports"
          containerClassName="ml-6"
          value={reportId}
          onChange={(e) => setReportId(e.target.value)}
        >
          <option disabled value="">
            Select a report
          </option>
          {filteredReports.map((r) => (
            <option key={r.id} value={r.id}>
              {r.name}
            </option>
          ))}
        </Select>
      ) : null}
    </div>
  )
}

export default PreviousReports
