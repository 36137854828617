import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { twMerge } from "tailwind-merge"
import Tooltip from "./Tooltip"

function InfoTooltip({
  as: El = "div",
  className,
  iconClassName,
  id,
  title,
  label,
}) {
  return (
    <El className={className}>
      <span className="align-middle">{title}</span>{" "}
      {label ? (
        <>
          <InformationCircleIcon
            id={id}
            className={twMerge("inline h-5 w-5", iconClassName)}
          />
          <Tooltip id={id}>{label}</Tooltip>
        </>
      ) : null}
    </El>
  )
}

export default InfoTooltip
