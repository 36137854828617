const AncillaryIcon = (props) => (
  <svg
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_1484_15045"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="48"
      height="48"
    >
      <rect width="48" height="48" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1484_15045)">
      <path
        d="M9.74609 40C8.97943 40 8.32943 39.7333 7.79609 39.2C7.26276 38.6667 6.99609 38.0167 6.99609 37.25V10.75C6.99609 9.98333 7.26276 9.33333 7.79609 8.8C8.32943 8.26667 8.97943 8 9.74609 8H29.1961V9.55H9.74609C9.41276 9.55 9.12943 9.66667 8.89609 9.9C8.66276 10.1333 8.54609 10.4167 8.54609 10.75V37.25C8.54609 37.5833 8.66276 37.8667 8.89609 38.1C9.12943 38.3333 9.41276 38.45 9.74609 38.45H36.2461C36.5794 38.45 36.8628 38.3333 37.0961 38.1C37.3294 37.8667 37.4461 37.5833 37.4461 37.25V17.8H38.9961V37.25C38.9961 38.0167 38.7294 38.6667 38.1961 39.2C37.6628 39.7333 37.0128 40 36.2461 40H9.74609ZM14.5461 33.05H16.0961V21.15H14.5461V33.05ZM22.2461 33.05H23.7461V14.95H22.2461V33.05ZM29.8961 33.05H31.4461V27.35H29.8961V33.05ZM35.2961 15.85V11.7H31.1461V10.15H35.2961V6H36.8461V10.15H40.9961V11.7H36.8461V15.85H35.2961Z"
        fill="white"
      />
    </g>
  </svg>
)

export default AncillaryIcon
