import { Tooltip as ReactTooltip } from "react-tooltip"

function Tooltip({ id, children }) {
  return (
    <ReactTooltip
      anchorId={id}
      style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" }}
    >
      <p className="w-max max-w-xs">{children}</p>
    </ReactTooltip>
  )
}

export default Tooltip
