import { useMemo, useRef } from "react"

function useInputDisabledForTypes(params) {
  const disabledForTypesRef = useRef(params.disabledForTypes)

  const disabled = useMemo(
    () =>
      params.disabled ??
      (params.value === undefined ||
        (disabledForTypesRef.current &&
          typeof params.planType !== "undefined" &&
          disabledForTypesRef.current.includes(params.planType))),
    [params.disabled, params.value, params.planType]
  )

  return disabled
}

export default useInputDisabledForTypes
