import { useEffect, useState } from "react"
import { Form, FormikProvider, getIn, useField, useFormik } from "formik"
import set from "lodash/set"
import update from "lodash/update"
import cloneDeep from "lodash/cloneDeep"
import BenchmarkingCategories from "../components/BenchmarkingCategories"
import ReportSettings from "../components/ReportSettings"
import ReportGeneratedModal from "../components/ReportGeneratedModal"
import ReportAddCensusFiles from "../components/ReportAddCensusFiles"
import FormCheckbox from "../components/FormCheckbox"
import ClientInformation from "../components/ClientInformation"
import FormTextArea from "../components/FormTextArea"
import usePrepareCreateMedicalReport from "../hooks/usePrepareCreateMedicalReport"
import PlanDetails from "../components/PlanDetails"
import Button from "../components/Button"
import Spinner from "../components/Spinner"
import Page from "../components/Page"
import Support from "../components/Support"
import PreviousReports from "../components/PreviousReports"
import FormRadioChip from "../components/FormRadioChip"
import InfoModal from "../components/InfoModal"
import InfoTooltip from "../components/InfoTooltip"
import api from "../utils/api"
import useMutation, { STATES } from "../hooks/useMutation"
import {
  PERCENTAGE_FIELDS,
  AUTOMATIC_INSIGHT_MANDATORY_FIELDS,
  REPORT_PARTS,
} from "../constants/plan"
import { DEFAULT_BENCHMARKING_SOURCE } from "../constants/common"
import ConfigValidationErrorsModal from "../components/ConfigValidationErrorsModal"

const MAX_CHARACTERS = 1024
const MAX_CHARACTERS_MESSAGE = `Max. ${MAX_CHARACTERS} characters`

function Config() {
  const [generatedReport, setGeneratedReport] = useState(null)
  const [d365Data, setD365Data] = useState({
    plans: [],
    metadata: null,
    company: {
      numberOfEmployees: null,
      accountId: null,
      state: "",
      industryCode: "",
      name: "",
    },
  })
  useEffect(() => window.d365Data.getData(setD365Data), [])

  const {
    isLoading: isPreparingPage,
    categoriesByYear,
    reports,
    isError,
    error,
  } = usePrepareCreateMedicalReport({
    numberOfEmployees: d365Data.company.numberOfEmployees,
    state: d365Data.company.state,
    industryCode: d365Data.company.industryCode,
  })
  const {
    mutate: createMedicalReport,
    isError: isErrorCreateMedicalReport,
    error: errorCreateMedicalReport,
    setState: setStateCreateMedicalReport,
  } = useMutation((body) => api.post("/v1/createMedicalReport", body))

  async function handleSubmit(
    {
      selectedPlan: _,
      census: censusValue,
      reportParts: reportPartsValue,
      playbookSelection,
      ...values
    },
    { setSubmitting }
  ) {
    const categories = Object.entries(values.categories).reduce(
      (acc, [key, values]) => {
        acc[key] = values.filter((v) => v.selected).map((v) => v.name)
        return acc
      },
      {}
    )

    let plans = []
    let census = []

    const { isBoth, isDemographic, isMedical } =
      getPlanSelectionValues(playbookSelection)

    if (isDemographic) {
      census = censusValue.data
    }

    if (isMedical && !values.benchmarkDataOnly) {
      plans = values.plans
        .filter(({ showPlanOnReport }) => showPlanOnReport)
        .map((p) => {
          const plan = cloneDeep(p)
          delete plan.showPlanOnReport
          plan.type = plan.type.toUpperCase()
          plan.simulated = plan.simulated === "proposed"
          PERCENTAGE_FIELDS.forEach((field) =>
            update(plan, field, (val) => val && parseFloat(val) / 100)
          )
          return plan
        })
    }
    const executiveSummary =
      (values.showExecutiveSummary && values.executiveSummary) || null

    const reportParts = Object.entries(reportPartsValue).reduce(
      (acc, [key, value]) => {
        if (key === "demographic") {
          acc[key] = isDemographic ? value : REPORT_PARTS[key]
        } else {
          acc[key] = isMedical ? value : REPORT_PARTS[key]
        }
        return acc
      },
      {}
    )

    const generatedReport = await createMedicalReport({
      ...values,
      plans,
      census,
      categories,
      reportParts,
      executiveSummary,
      reportType: isBoth ? "medical-demographic" : playbookSelection,
      metadata: d365Data.metadata,
      accountId: d365Data.company.accountId,
    })
    setSubmitting(false)
    setGeneratedReport(generatedReport)
  }

  async function handleValidate(values) {
    const errors = {}

    if (
      values.executiveSummary &&
      values.executiveSummary.length === MAX_CHARACTERS
    ) {
      errors.executiveSummary = MAX_CHARACTERS_MESSAGE
    }

    // client information validation
    if (!values.groupName) {
      errors.groupName = MANDATORY_FIELD
    }
    if (!values.reportName) {
      errors.reportName = MANDATORY_FIELD
    }
    if (!values.industryCode) {
      errors.industryCode = MANDATORY_FIELD
    }
    if (!values.state) {
      errors.state = MANDATORY_FIELD
    }
    if (typeof values.numberOfEmployees !== "number") {
      errors.numberOfEmployees = MANDATORY_FIELD
    }

    const { isDemographic, isMedical } = getPlanSelectionValues(
      values.playbookSelection
    )

    if (isDemographic) {
      if (!values.census) {
        errors.census = MANDATORY_FIELD
      }

      const graphsSelected = someGraphSelected(values.reportParts, [
        "demographic",
      ])
      if (!graphsSelected) {
        errors.reportParts = ["At least 1 demographic graph must be selected."]
      }
    }

    if (isMedical) {
      const graphsSelected = someGraphSelected(values.reportParts, [
        "ppo",
        "hmo",
        "hdhp",
      ])
      if (!graphsSelected) {
        errors.reportParts = [
          ...(errors.reportParts || []),
          "At least 1 PPO, HMO or HDHP graph must be selected.",
        ]
      }
      if (values.benchmarkDataOnly) {
        if (
          !Object.values(values.categories)
            .flat()
            .some((c) => c.selected)
        ) {
          errors.categories = MANDATORY_FIELD
        }
      } else {
        if (values.plans.length === 0) {
          errors.plans = MANDATORY_FIELD
        } else {
          if (values.addAutomaticInsight) {
            values.plans.forEach((plan, index) => {
              if (plan.showPlanOnReport) {
                AUTOMATIC_INSIGHT_MANDATORY_FIELDS.forEach((field) =>
                  generatePlanMandatoryErrors({
                    errors,
                    plan,
                    index,
                    field,
                  })
                )
              }
            })
          }
          values.plans.forEach((plan, index) => {
            if (plan.showPlanOnReport) {
              generatePlanMandatoryErrors({
                errors,
                plan,
                index,
                field: "simulated",
              })
            }
          })
        }
      }
    }

    return errors
  }

  const initCategories = categoriesByYear[DEFAULT_BENCHMARKING_SOURCE]

  const formik = useFormik({
    initialValues: {
      census: null,
      reportName: "",
      selectedPlan: "", // don't submit
      executiveSummary: "",
      showExecutiveSummary: false,
      addAutomaticInsight: null,
      benchmarkDataOnly: false,
      reportParts: REPORT_PARTS,
      plans: d365Data.plans,
      state: d365Data.company.state,
      groupName: d365Data.company.name,
      industryCode: d365Data.company.industryCode,
      numberOfEmployees: d365Data.company.numberOfEmployees,
      playbookSelection: "both",
      benchmarkSource: DEFAULT_BENCHMARKING_SOURCE,
      categories: initCategories,
    },
    validate: handleValidate,
    onSubmit: handleSubmit,
    validateOnChange: false,
  })

  const setFormikValues = formik.setValues
  useEffect(() => {
    setFormikValues((values) => ({
      ...values,
      categories: initCategories,
      plans: d365Data.plans,
      state: d365Data.company.state,
      groupName: d365Data.company.name,
      industryCode: d365Data.company.industryCode,
      numberOfEmployees: d365Data.company.numberOfEmployees,
    }))
  }, [setFormikValues, initCategories, d365Data])

  const { isDemographic, isMedical } = getPlanSelectionValues(
    formik.values.playbookSelection
  )

  return (
    <Page className="px-8 py-4 font-segoe">
      {isError ? (
        <div className="flex justify-center">{error}</div>
      ) : isPreparingPage ? (
        <SpinnerLoading label="Loading..." />
      ) : (
        <FormikProvider value={formik}>
          {formik.isSubmitting ? (
            <SpinnerLoading label="Generating report..." />
          ) : (
            <>
              <div className="border-b-[0.031rem] border-gray-nurse pb-8">
                {reports ? (
                  <PreviousReports
                    reports={reports}
                    categories={initCategories}
                    setValues={formik.setValues}
                    accountId={d365Data.company.accountId}
                  />
                ) : null}
                <div className="mt-11 flex flex-col gap-y-2">
                  <InfoTooltip
                    id="playbook-selection"
                    as="span"
                    className="text-sm font-semibold text-tuatara"
                    title="Playbook Selection"
                    label="The Playbook selection determines which form sections will be available for input.
                          For Medical & Rx Benchmarking Playbook: Client Information, Benchmarking Categories,
                          Report Settings, Plan Information. For Demographics Playbook: Client Information, Census Files."
                  />
                  <div className="flex gap-x-1">
                    <PlaybookSelectionChip value="both">
                      Medical & Rx Benchmarking Playbook and Demographics
                    </PlaybookSelectionChip>
                    <PlaybookSelectionChip value="medical">
                      Medical & Rx Benchmarking
                    </PlaybookSelectionChip>
                    <PlaybookSelectionChip value="demographic">
                      Demographics
                    </PlaybookSelectionChip>
                  </div>
                </div>
              </div>

              <Form className="mt-5 flex flex-col space-y-12">
                <div className="space-y-5">
                  <ClientInformation />
                  <BenchmarkingCategories categoriesByYear={categoriesByYear} />
                  <ReportSettings
                    disabledAddAutomaticInsight={
                      formik.values.benchmarkDataOnly
                    }
                    isDemographic={isDemographic}
                    isMedical={isMedical}
                  />
                  {isDemographic ? (
                    <ReportAddCensusFiles
                      census={formik.values.census}
                      onChange={(census) =>
                        formik.setFieldValue("census", census)
                      }
                    />
                  ) : null}
                  {isMedical && !formik.values.benchmarkDataOnly ? (
                    <PlanDetails />
                  ) : null}
                </div>
                <div className="space-y-5">
                  <FormCheckbox
                    name="showExecutiveSummary"
                    label="Add Executive Summary"
                  />
                  {formik.values.showExecutiveSummary ? (
                    <MaxLengthTextArea
                      name="executiveSummary"
                      label="Executive Summary"
                    />
                  ) : null}
                </div>
                <div className="flex items-center justify-between">
                  <Support />
                  <Button type="submit" disabled={formik.isSubmitting}>
                    Generate
                  </Button>
                </div>
              </Form>
            </>
          )}
          <ConfigValidationErrorsModal
            submitCount={formik.submitCount}
            isSubmitting={formik.isSubmitting}
            isValidating={formik.isValidating}
            errors={formik.errors}
            values={formik.values}
          />
        </FormikProvider>
      )}

      <ReportGeneratedModal
        report={generatedReport}
        onClose={() => setGeneratedReport(null)}
      />

      <InfoModal
        show={isErrorCreateMedicalReport}
        classNamePanel="max-w-lg"
        onClose={() => setStateCreateMedicalReport(STATES.IDLE)}
        title="Error creating medical report"
      >
        {errorCreateMedicalReport}
      </InfoModal>
    </Page>
  )
}

function PlaybookSelectionChip({ value, children }) {
  return (
    <FormRadioChip name="playbookSelection" value={value}>
      {children} Playbook
    </FormRadioChip>
  )
}

function SpinnerLoading({ label }) {
  return (
    <div className="flex flex-1 items-center justify-center">
      <Spinner label={label} />
    </div>
  )
}

function MaxLengthTextArea({ name, label }) {
  const [field, meta, helpers] = useField(name)
  const [showCharacters, setShowCharacters] = useState(false)
  return (
    <div className="ml-6 focus:border">
      <FormTextArea
        label={label}
        name={name}
        maxLength={MAX_CHARACTERS}
        error={meta.error}
        onFocus={() => setShowCharacters(true)}
        onChange={(e) =>
          helpers.setValue(e.target.value.slice(0, MAX_CHARACTERS))
        }
        onBlur={(e) => {
          setShowCharacters(false)
          field.onBlur(e)
        }}
      />
      {!meta.error && showCharacters ? (
        <div className="mt-1 text-xs text-lochmara">
          {!!meta.value
            ? `${MAX_CHARACTERS - meta.value.length} characters left`
            : MAX_CHARACTERS_MESSAGE}
        </div>
      ) : null}
    </div>
  )
}

function generatePlanMandatoryErrors({ errors, plan, index, field }) {
  const val = getIn(plan, field)
  if ([null, undefined, ""].includes(val)) {
    set(errors, `plans[${index}].${field}`, MANDATORY_FIELD)
  }
}

function someGraphSelected(reportParts, graphs) {
  return graphs.some((key) => Object.values(reportParts[key]).some(Boolean))
}

function getPlanSelectionValues(playbookSelection) {
  const isBoth = playbookSelection === "both"
  const isDemographic = isBoth || playbookSelection === "demographic"
  const isMedical = isBoth || playbookSelection === "medical"
  return { isBoth, isDemographic, isMedical }
}

const MANDATORY_FIELD = "Mandatory field"

export default Config
