const PayIcon = (props) => (
  <svg
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_1484_15029"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="48"
      height="48"
    >
      <rect width="48" height="48" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1484_15029)">
      <path
        d="M23.15 37.4H24.75V35C26.35 34.9333 27.7917 34.4333 29.075 33.5C30.3583 32.5667 31 31.2 31 29.4C31 27.8667 30.5417 26.6333 29.625 25.7C28.7083 24.7667 27.0667 23.8333 24.7 22.9C22.5 22.0667 21.075 21.35 20.425 20.75C19.775 20.15 19.45 19.2833 19.45 18.15C19.45 17.0833 19.8833 16.1833 20.75 15.45C21.6167 14.7167 22.7333 14.35 24.1 14.35C25.1 14.35 25.9667 14.575 26.7 15.025C27.4333 15.475 28.0667 16.0833 28.6 16.85L29.95 16.25C29.3833 15.2833 28.6583 14.5167 27.775 13.95C26.8917 13.3833 25.9167 13.0667 24.85 13V10.6H23.25V13C21.4833 13.2333 20.1417 13.8333 19.225 14.8C18.3083 15.7667 17.85 16.8833 17.85 18.15C17.85 19.65 18.3167 20.8333 19.25 21.7C20.1833 22.5667 21.7333 23.4167 23.9 24.25C26.1333 25.1167 27.6167 25.9 28.35 26.6C29.0833 27.3 29.45 28.2333 29.45 29.4C29.45 30.8 28.9167 31.8417 27.85 32.525C26.7833 33.2083 25.6 33.55 24.3 33.55C23.1 33.55 21.9917 33.2083 20.975 32.525C19.9583 31.8417 19.1833 30.8833 18.65 29.65L17.2 30.25C17.8667 31.5833 18.6917 32.6083 19.675 33.325C20.6583 34.0417 21.8167 34.5667 23.15 34.9V37.4ZM24 42C21.5333 42 19.2083 41.525 17.025 40.575C14.8417 39.625 12.9333 38.3333 11.3 36.7C9.66667 35.0667 8.375 33.1583 7.425 30.975C6.475 28.7917 6 26.4667 6 24C6 21.5 6.475 19.1583 7.425 16.975C8.375 14.7917 9.66667 12.8917 11.3 11.275C12.9333 9.65833 14.8417 8.375 17.025 7.425C19.2083 6.475 21.5333 6 24 6C26.5 6 28.8417 6.475 31.025 7.425C33.2083 8.375 35.1083 9.65833 36.725 11.275C38.3417 12.8917 39.625 14.7917 40.575 16.975C41.525 19.1583 42 21.5 42 24C42 26.4667 41.525 28.7917 40.575 30.975C39.625 33.1583 38.3417 35.0667 36.725 36.7C35.1083 38.3333 33.2083 39.625 31.025 40.575C28.8417 41.525 26.5 42 24 42ZM24 40.45C28.5667 40.45 32.45 38.85 35.65 35.65C38.85 32.45 40.45 28.5667 40.45 24C40.45 19.4333 38.85 15.55 35.65 12.35C32.45 9.15 28.5667 7.55 24 7.55C19.4333 7.55 15.55 9.15 12.35 12.35C9.15 15.55 7.55 19.4333 7.55 24C7.55 28.5667 9.15 32.45 12.35 35.65C15.55 38.85 19.4333 40.45 24 40.45Z"
        fill="white"
      />
    </g>
  </svg>
)

export default PayIcon
