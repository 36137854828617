import InformationSection from "./InformationSection"
import Subsection from "./Subsection"
import FormPlanInput from "./FormPlanInput"
import Link from "./Link"
import { PLAN_TYPES } from "../constants/plan"

function MedicalPlanCost() {
  return (
    <InformationSection title="Medical Plan Cost" contentClassName="gap-y-12">
      <Subsection title="Monthly Tier Contribution ($)">
        <FormPlanInput
          name="medicalPlanCost.monthlyTierContribution.individual"
          label="Individual"
        />
        <FormPlanInput
          name="medicalPlanCost.monthlyTierContribution.family"
          label="Family"
        />
      </Subsection>
      <Subsection title="Monthly Tier Contribution (%)">
        <FormPlanInput
          type="percentage"
          name="medicalPlanCost.monthlyTierContributionPercentage.individual"
          label="Individual"
        />
        <FormPlanInput
          type="percentage"
          name="medicalPlanCost.monthlyTierContributionPercentage.family"
          label="Family"
        />
      </Subsection>
      <Subsection title="Monthly Premium">
        <FormPlanInput
          name="medicalPlanCost.monthlyPremium.individual"
          label="Individual"
        />
        <FormPlanInput
          name="medicalPlanCost.monthlyPremium.family"
          label="Family"
        />
      </Subsection>
      <Subsection title="Employer HSA Contribution">
        <FormPlanInput
          name="medicalPlanCost.employerHsaContribution.individual"
          label="Individual"
          disabledForTypes={[PLAN_TYPES.PPO, PLAN_TYPES.HMO]}
        />
        <FormPlanInput
          name="medicalPlanCost.employerHsaContribution.family"
          label="Family"
          disabledForTypes={[PLAN_TYPES.PPO, PLAN_TYPES.HMO]}
        />
      </Subsection>
      <Subsection>
        <FormPlanInput
          type="percentage"
          name="medicalPlanCost.actuarial.actuarialValues"
          label="Actuarial values"
        />
        <span className="text-xs">
          Please use the{" "}
          <Link
            to="https://dish.digitalinsurance.com/content/138724/models-trainings#folder=140277"
            className="text-allports underline"
            isExternal
          >
            CompPricer AV Tool
          </Link>
        </span>
      </Subsection>
    </InformationSection>
  )
}

export default MedicalPlanCost
