import { forwardRef } from "react"
import clsx from "clsx"
import GenericButton from "./GenericButton"

const ReportButton = forwardRef(
  ({ className, variant = "solid", ...props }, ref) => {
    return (
      <GenericButton
        ref={ref}
        className={clsx(className, {
          "border-transparent bg-prussian-blue text-white hover:bg-daintree active:bg-maastricht-blue":
            variant === "solid",
          "border-prussian-blue bg-transparent text-prussian-blue hover:bg-daintree/5 active:bg-maastricht-blue/10":
            variant === "outline",
        })}
        {...props}
      />
    )
  }
)

export default ReportButton
