import { Formik, Form } from "formik"
import FormInput from "./FormInput"
import FormPlanType from "./FormPlanType"
import Modal from "./Modal"
import Button from "./Button"

function AddPlanModal({ show, onConfirm, onClose }) {
  function handleSubmit(values, { setSubmitting }) {
    onConfirm({
      ...values,
      showPlanOnReport: true,
      simulated: null,
      medicalPlanCost: {
        monthlyTierContribution: {
          individual: null,
          family: null,
        },
        monthlyTierContributionPercentage: {
          individual: null,
          family: null,
        },
        monthlyPremium: {
          individual: null,
          family: null,
        },
        actuarial: {
          actuarialValues: null,
        },
        employerHsaContribution: {
          individual: null,
          family: null,
        },
      },
      medicalPlanDesign: {
        inNetworkTierDeductible: {
          individual: null,
          family: null,
        },
        inNetworkTierOutOfPocketMax: {
          individual: null,
          family: null,
        },
        coinsurance: {
          coinsurance: null,
          inPatientHospitalStay: null,
        },
        copayment: {
          primaryCarePhysician: null,
          specialPhysician: null,
          emergencyRoom: null,
          inpatientHospitalStay: null,
        },
      },
      rxPlanDesign: {
        genericRx: null,
        brandNameFormularyRx: null,
        brandNameNonFormularyRx: null,
        specialtyBiotechRx: null,
      },
    })
    setSubmitting(false)
    onClose()
  }
  return (
    <Modal
      title="Add New Plan"
      show={show}
      onClose={onClose}
      data-test="add-plan-modal"
    >
      <Formik
        initialValues={{
          name: "",
          type: "",
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          <div className="max-w-xs space-y-7">
            <FormInput type="text" name="name" label="Plan Name" />
            <FormPlanType name="type" />
          </div>

          <div className="mt-8 flex justify-end gap-x-2">
            <Button type="submit">Confirm</Button>
            <Button variant="outline" onClick={onClose}>
              Dismiss
            </Button>
          </div>
        </Form>
      </Formik>
    </Modal>
  )
}

export default AddPlanModal
