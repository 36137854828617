import Label from "./Label"
import { getButtonStyles } from "../utils/styles"
import InputContainer from "./InputContainer"
import ErrorInput from "./ErrorInput"

function TextArea({ name, label, error, classNames = {}, ...props }) {
  return (
    <InputContainer className={classNames.container}>
      <Label htmlFor={name}>{label}</Label>
      <textarea
        id={name}
        name={name}
        className={getButtonStyles({
          showError: !!error,
        })}
        {...props}
      />
      <ErrorInput>{error}</ErrorInput>
    </InputContainer>
  )
}

export default TextArea
