import React from "react"
import { useSearchParams } from "react-router-dom"
import MedicalIcon from "../components/icons/MedicalIcon"
import PayIcon from "../components/icons/PayIcon"
import AncillaryIcon from "../components/icons/AncillaryIcon"
import VoluntaryIcon from "../components/icons/VoluntaryIcon"
import RetirementIcon from "../components/icons/RetirementIcon"
import Support from "../components/Support"
import Link from "../components/Link"

const WELCOME_TILES = [
  {
    title: "Medical and Demographic Benchmarking",
    actionBtn: "Go to application",
    icon: <MedicalIcon alt="medicalIcon" className="h-12 w-12" />,
    bgColor: "bg-prussian-blue",
    width: "w-full",
    isActive: true,
    mvpUser: "/config",
    nonMvpUser:
      "https://dish.digitalinsurance.com/content/141987/benchmarking-digital-playbooks",
  },
  {
    title: "Pay Transparency",
    icon: <PayIcon alt="payIcon" className="h-12 w-12" />,
    bgColor: "bg-allports/[0.65]",
    width: "w-full",
    isActive: false,
  },
  {
    title: "Ancillary Benchmarking",
    icon: <AncillaryIcon alt="ancillaryIcon" className="h-12 w-12" />,
    bgColor: "bg-wasabi/[0.65]",
    width: "w-3/5",
    isActive: false,
  },
  {
    title: "Voluntary Benchmarking",
    icon: <VoluntaryIcon alt="voluntaryIcon" className="h-12 w-12" />,
    bgColor: "bg-orange-roughy/[0.65]",
    width: "w-3/5",
    isActive: false,
  },
  {
    title: "Retirement Benchmarking",
    icon: <RetirementIcon alt="retirementIcon" className="h-12 w-12" />,
    bgColor: "bg-stack/[0.65]",
    width: "w-3/5",
    isActive: false,
  },
]

const Welcome = () => {
  const [URLSearchParams] = useSearchParams()
  const isMvpUser = URLSearchParams.get("benchmarkingUser") === "true"

  return (
    <main className="my-5 mx-8 font-segoe text-tuatara">
      <h1 className="text-3xl">Benchmarking Digital Playbook</h1>
      <div className="mt-10 mb-12 bg-concrete py-5 px-6">
        <p>
          Welcome to the Benchmarking Digital Playbook. The Benchmarking
          products below will assist you in creating client facing reports to
          compare employer plan offerings against other businesses in the
          market. Here you will find the different benchmarking reports that are
          available for your client. If you have any questions or need support,
          please reach out to us via
          <span className="text-allports">
            {" "}
            <a href="mailto:benchmarkingsupport@onedigital.com">
              benchmarkingsupport@onedigital.com
            </a>
          </span>
          . To get started, select the medical benchmarking module below:
        </p>
      </div>
      <div className="flex flex-wrap gap-8">
        {WELCOME_TILES.map((tile) => (
          <div
            className={`flex h-48 w-64 grow flex-col justify-between px-6 py-5 text-white shadow-tile ${tile.bgColor}`}
            key={tile.title}
          >
            <div>
              <div className="h-px w-full bg-white" />
              <h2 className={`my-3 text-xl leading-6 ${tile.width}`}>
                {tile.title}
              </h2>
              {tile.icon}
            </div>
            {tile.isActive ? (
              <Link
                isExternal={!isMvpUser}
                to={isMvpUser ? tile.mvpUser : tile.nonMvpUser}
                className="hover:font-semibold"
              >
                {tile.actionBtn}
              </Link>
            ) : (
              <span>Coming soon</span>
            )}
          </div>
        ))}
      </div>
      <Support className="mt-10" />
    </main>
  )
}

export default Welcome
