import { Field } from "formik"
import Checkbox from "./Checkbox"

function FormCheckbox({ name, ...props }) {
  return (
    <Field name={name} type="checkbox">
      {({ field }) => <Checkbox {...props} {...field} />}
    </Field>
  )
}

export default FormCheckbox
