import FormInput from "./FormInput"
import FormSelect from "./FormSelect"
import FormTextArea from "./FormTextArea"
import InformationSection from "./InformationSection"

function ClientInformation() {
  return (
    <InformationSection title="Client Information" contentClassName="gap-y-11">
      <WithSubtitle subtitle="The group name you enter will be shown on the generated report.">
        <FormInput type="text" name="groupName" label="Group Name" />
      </WithSubtitle>
      <WithSubtitle subtitle="The report name you enter will be shown on the generated report.">
        <FormTextArea name="reportName" label="Report Name" />
      </WithSubtitle>
      <FormInput
        type="number"
        name="numberOfEmployees"
        label="Number of Employees"
      />
      <FormInput type="text" name="industryCode" label="SIC Code" />
      <FormSelect name="state" label="State">
        <option disabled value="">
          None
        </option>
        {STATES.map((s, i) => (
          <option key={i} value={s}>
            {s}
          </option>
        ))}
      </FormSelect>
    </InformationSection>
  )
}

function WithSubtitle({ children, subtitle }) {
  return (
    <div>
      {children}
      <span className="text-xs">{subtitle}</span>
    </div>
  )
}

const STATES = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
]

export default ClientInformation
