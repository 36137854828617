import { FieldArray } from "formik"
import PlanInformation from "../components/PlanInformation"
import MedicalPlanDesign from "../components/MedicalPlanDesign"
import RxPlanDesign from "../components/RxPlanDesign"
import MedicalPlanCost from "../components/MedicalPlanCost"

function PlanDetails() {
  return (
    <FieldArray name="plans">
      {({ push }) => (
        <>
          <PlanInformation onAddPlan={push} />
          <MedicalPlanCost />
          <MedicalPlanDesign />
          <RxPlanDesign />
        </>
      )}
    </FieldArray>
  )
}

export default PlanDetails
