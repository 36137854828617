const MedicalIcon = (props) => (
  <svg
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_1484_15021"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="48"
      height="48"
    >
      <rect width="48" height="48" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1484_15021)">
      <path
        d="M23.3 31.6C22.3 32.5333 21.1417 32.9917 19.825 32.975C18.5083 32.9583 17.3667 32.5 16.4 31.6C15.5 30.6333 15.0417 29.4917 15.025 28.175C15.0083 26.8583 15.4667 25.7167 16.4 24.75L24.7 16.4C25.6667 15.4667 26.825 15 28.175 15C29.525 15 30.6667 15.4667 31.6 16.4C32.5667 17.3667 33.0417 18.5083 33.025 19.825C33.0083 21.1417 32.5333 22.2833 31.6 23.25L23.3 31.6ZM17.5 30.45C18.2667 31.2167 19.1083 31.55 20.025 31.45C20.9417 31.35 21.65 31.0333 22.15 30.5L25.75 26.85L21.15 22.25L17.5 25.85C16.8333 26.4833 16.5 27.25 16.5 28.15C16.5 29.05 16.8333 29.8167 17.5 30.45ZM30.5 17.55C29.7333 16.7833 28.8917 16.45 27.975 16.55C27.0583 16.65 26.35 16.9667 25.85 17.5L22.25 21.15L26.85 25.75L30.5 22.15C31.1667 21.5167 31.4917 20.75 31.475 19.85C31.4583 18.95 31.1333 18.1833 30.5 17.55ZM10.75 40C10.0167 40 9.375 39.725 8.825 39.175C8.275 38.625 8 37.9833 8 37.25V10.75C8 10.0167 8.275 9.375 8.825 8.825C9.375 8.275 10.0167 8 10.75 8H21C20.7333 7 20.9083 6.08333 21.525 5.25C22.1417 4.41667 22.9667 4 24 4C25.0667 4 25.9083 4.41667 26.525 5.25C27.1417 6.08333 27.3 7 27 8H37.25C37.9833 8 38.625 8.275 39.175 8.825C39.725 9.375 40 10.0167 40 10.75V37.25C40 37.9833 39.725 38.625 39.175 39.175C38.625 39.725 37.9833 40 37.25 40H10.75ZM10.75 38.45H37.25C37.55 38.45 37.825 38.325 38.075 38.075C38.325 37.825 38.45 37.55 38.45 37.25V10.75C38.45 10.45 38.325 10.175 38.075 9.925C37.825 9.675 37.55 9.55 37.25 9.55H10.75C10.45 9.55 10.175 9.675 9.925 9.925C9.675 10.175 9.55 10.45 9.55 10.75V37.25C9.55 37.55 9.675 37.825 9.925 38.075C10.175 38.325 10.45 38.45 10.75 38.45ZM24 8.75C24.4333 8.75 24.8167 8.59167 25.15 8.275C25.4833 7.95833 25.65 7.56667 25.65 7.1C25.65 6.66667 25.4833 6.29167 25.15 5.975C24.8167 5.65833 24.4333 5.5 24 5.5C23.5667 5.5 23.1917 5.65833 22.875 5.975C22.5583 6.29167 22.4 6.66667 22.4 7.1C22.4 7.56667 22.5583 7.95833 22.875 8.275C23.1917 8.59167 23.5667 8.75 24 8.75Z"
        fill="white"
      />
    </g>
  </svg>
)

export default MedicalIcon
