import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { datadogInit } from "./datadogMetrics"
import ProtectedPage from "./components/ProtectedPage"
import Config from "./pages/config"
import Report from "./pages/report"
import Welcome from "./pages/welcome"

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedPage>
        <Welcome />
      </ProtectedPage>
    ),
  },
  {
    path: "/config",
    element: (
      <ProtectedPage>
        <Config />
      </ProtectedPage>
    ),
  },
  {
    path: "/report",
    element: <Report />,
  },
])

datadogInit()

function App() {
  return <RouterProvider router={router} />
}

export default App
