import { useState, useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import Page from "./Page"
import api from "../utils/api"
import Spinner from "./Spinner"

function ProtectedPage({ children }) {
  const [URLSearchParams] = useSearchParams()
  const [hasAccess, setHasAccess] = useState(false)

  useEffect(
    function checkTokenEffect() {
      const token = URLSearchParams.get("auth")
      if (token) {
        api
          .post("/v1/validate", null, {
            json: false,
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(() => {
            sessionStorage.setItem("token", token)
            setHasAccess(true)
          })
          .catch(redirect)
      } else if (sessionStorage.getItem("token")) {
        setHasAccess(true)
      } else {
        redirect()
      }
    },
    [URLSearchParams]
  )

  if (hasAccess) {
    return children
  }

  return (
    <Page className="items-center justify-center">
      <Spinner label="Loading!" />
    </Page>
  )
}

function redirect() {
  window.location.href = "https://www.onedigital.com/"
}

export default ProtectedPage
