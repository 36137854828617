import { Switch as HeadlessuiSwitch } from "@headlessui/react"
import { getLabelStyles } from "../utils/styles"
import InfoTooltip from "./InfoTooltip"

function Switch({ checked, label, tooltipProps, ...props }) {
  return (
    <HeadlessuiSwitch.Group>
      <div className="inline-flex flex-col">
        {label ? (
          tooltipProps ? (
            <InfoTooltip
              as={HeadlessuiSwitch.Label}
              title={label}
              {...tooltipProps}
              className={getLabelStyles()}
            />
          ) : (
            <HeadlessuiSwitch.Label className={getLabelStyles()}>
              {label}
            </HeadlessuiSwitch.Label>
          )
        ) : null}
        <HeadlessuiSwitch
          checked={checked}
          className={`${
            checked ? "bg-allports" : "bg-gray-200"
          } relative inline-flex h-6 w-11 items-center rounded-full`}
          {...props}
        >
          <span
            className={`${
              checked ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
          />
        </HeadlessuiSwitch>
      </div>
    </HeadlessuiSwitch.Group>
  )
}

export default Switch
