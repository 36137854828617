import { useEffect, useState } from "react"
import { getIn } from "formik"
import { AUTOMATIC_INSIGHT_MANDATORY_FIELDS } from "../constants/plan"
import InfoModal from "./InfoModal"

function ConfigValidationErrorsModal({
  submitCount,
  errors,
  isSubmitting,
  isValidating,
  values,
}) {
  const [internalSubmitCount, setInternalSubmitCount] = useState(submitCount)
  const [showValidationErrors, setShowValidationErrors] = useState(false)
  const [errorsDesc, setErrorsDesc] = useState(null)

  useEffect(() => {
    if (internalSubmitCount !== submitCount && !isSubmitting && !isValidating) {
      setInternalSubmitCount(submitCount)

      let tempErrors = []
      let automaticInsightError = null
      const { plans, categories, census, reportParts, ...restErrors } = errors

      if (typeof plans === "string") {
        tempErrors.push("At least 1 plan required.")
      } else {
        plans?.forEach((plan, index) => {
          if (
            !automaticInsightError &&
            AUTOMATIC_INSIGHT_MANDATORY_FIELDS.some((field) =>
              getIn(plan, field)
            )
          ) {
            automaticInsightError =
              "Since automatic insights have been activated, all medical plan cost fields must be completed."
          }
          if (plan.simulated) {
            tempErrors.push(
              `Please select whether ${values.plans[index].name} is an existing plan or not.`
            )
          }
        })
      }
      if (automaticInsightError) {
        tempErrors = [automaticInsightError, ...tempErrors]
      }
      if (categories) {
        tempErrors.push("At least 1 benchmarking category required.")
      }
      if (reportParts?.length > 0) {
        tempErrors.push(...reportParts)
      }
      if (census) {
        tempErrors.push("Census file required.")
      }
      if (Object.keys(restErrors).length > 0) {
        tempErrors.push("Check all fields are properly filled.")
      }
      if (tempErrors.length > 0) {
        setShowValidationErrors(true)
        setErrorsDesc(tempErrors)
      }
    }
  }, [
    internalSubmitCount,
    submitCount,
    isSubmitting,
    isValidating,
    errors,
    values,
  ])

  return (
    <InfoModal
      show={showValidationErrors}
      classNamePanel="max-w-lg"
      title="Some required fields are missing information"
      onClose={() => setShowValidationErrors(false)}
      afterLeave={() => setErrorsDesc(null)}
    >
      {errorsDesc ? (
        <ul className="list-inside list-disc space-y-1 text-sm text-chicago">
          {errorsDesc.map((e, i) => (
            <li key={i}>{e}</li>
          ))}
        </ul>
      ) : null}
    </InfoModal>
  )
}

export default ConfigValidationErrorsModal
