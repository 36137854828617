import Label from "./Label"
import FormRadioChip from "./FormRadioChip"
import InputContainer from "./InputContainer"
import { PLAN_TYPES } from "../constants/plan"

function FormPlanType({ name }) {
  return (
    <InputContainer>
      <Label>Plan type</Label>
      <div className="space-x-1">
        <FormRadioChip name={name} value={PLAN_TYPES.PPO}>
          PPO/POS
        </FormRadioChip>
        <FormRadioChip name={name} value={PLAN_TYPES.HMO}>
          HMO
        </FormRadioChip>
        <FormRadioChip name={name} value={PLAN_TYPES.HDHP}>
          HDHP
        </FormRadioChip>
      </div>
    </InputContainer>
  )
}

export default FormPlanType
