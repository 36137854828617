import { CheckIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"
import { useState } from "react"

function Chip({ id, showCheck, children, disabled, value, onChange }) {
  const [internalValue, setInternalValue] = useState(false)

  // if value is undefined or null, use interval value.
  // we do this to allow using the component in controlled and uncontrolled mode.
  const finalValue = value ?? internalValue
  return (
    <button
      id={id}
      type="button"
      onClick={() => {
        const newVal = !finalValue
        setInternalValue(newVal)
        if (onChange) {
          onChange(newVal)
        }
      }}
      disabled={disabled}
      className={clsx(
        "inline-flex w-max items-center justify-center space-x-1.5 rounded-full py-1.5 px-2 text-sm font-normal",
        {
          "bg-allports text-white hover:bg-prussian-blue":
            !disabled && finalValue,
          "bg-bright-gray text-shark hover:bg-gray-nurse hover:text-tuatara":
            !disabled && !finalValue,
          "bg-ebb text-dawn": disabled,
        }
      )}
    >
      {showCheck ? <CheckIcon className="h-5 w-5" /> : null}
      <span>{children}</span>
    </button>
  )
}

export default Chip
