import clsx from "clsx"
import Link from "./Link"

const LINK_STYLE = "font-semibold text-allports underline"

function Support({ className }) {
  return (
    <p className={clsx("text-xs leading-5 text-shark", className)}>
      Are you looking for a different type of report?{" "}
      <Link
        isExternal
        to="https://onedigital.surveymonkey.com/r/XPLY7HR"
        className={LINK_STYLE}
      >
        Let us know here.
      </Link>
      <br />
      Do you need support?{" "}
      <a
        href="mailto:benchmarkingsupport@onedigital.com"
        className={LINK_STYLE}
      >
        Reach us out here.
      </a>
    </p>
  )
}

export default Support
