import { useFormikContext, useField } from "formik"
import CurrencyInput from "./CurrencyInput"
import Input from "./Input"
import useInputDisabledForTypes from "../hooks/useInputDisabledForTypes"

function FormPlanInput({
  name: relativeName,
  type = "currency",
  disabled,
  disabledForTypes,
  ...props
}) {
  const { values } = useFormikContext()
  const name = `plans.${values.selectedPlan}.${relativeName}`
  const [field, meta, { setValue }] = useField(name)

  const planType = values.plans[values.selectedPlan]?.type

  const internalDisabled = useInputDisabledForTypes({
    disabledForTypes,
    planType,
    disabled,
    value: field.value,
  })

  const error = meta.touched ? meta.error : ""
  const value = field.value ?? ""

  return type === "currency" || type === "percentage" ? (
    <CurrencyInput
      {...props}
      name={name}
      prefix={type === "currency" ? "$" : undefined}
      suffix={type === "percentage" ? "%" : undefined}
      decimalSeparator="."
      groupSeparator=","
      data-test="form-plan-input"
      onValueChange={setValue}
      value={value}
      onBlur={field.onBlur}
      disabled={internalDisabled}
      error={error}
    />
  ) : (
    <Input
      {...props}
      {...field}
      data-test="form-plan-input"
      type={type}
      onChange={(e) => setValue(e.target.value)}
      value={value}
      disabled={internalDisabled}
      error={error}
    />
  )
}

export default FormPlanInput
