import { useField } from "formik"
import Switch from "./Switch"

function FormSwitch({ name, label, ...props }) {
  // eslint-disable-next-line no-unused-vars
  const [field, _, { setValue }] = useField({ name, type: "checkbox" })

  return (
    <Switch
      {...props}
      label={label}
      checked={field.checked}
      onChange={setValue}
      onBlur={field.onBlur}
    />
  )
}

export default FormSwitch
