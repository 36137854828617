import { Fragment } from "react"
import { Popover, Transition } from "@headlessui/react"
import { useFloating, offset, flip, shift } from "@floating-ui/react"
import { getButtonStyles } from "../utils/styles"
import FormCheckbox from "./FormCheckbox"
import Checkbox from "./Checkbox"
import InfoTooltip from "./InfoTooltip"

function MultiSelectPopover({
  data,
  onSelectAll,
  title,
  tooltip,
  tooltipId,
  dataTypeSubtitle,
  selectedOptionsAmount,
  totalOptionsAmount,
}) {
  const { x, y, strategy, refs } = useFloating({
    placement: "bottom",
    middleware: [offset(5), flip(), shift({ padding: 5 })],
  })

  const isAllSelected = selectedOptionsAmount === totalOptionsAmount

  return (
    <Popover className="relative">
      <InfoTooltip
        id={tooltipId}
        as="span"
        className="text-sm font-semibold text-shark"
        title={title}
        label={tooltip}
      />
      <Popover.Button
        ref={refs.setReference}
        className={getButtonStyles({
          className: "form-select mt-1.5 w-full text-left",
        })}
      >
        <span>
          {selectedOptionsAmount === 0
            ? "Select"
            : `${
                isAllSelected ? "All" : selectedOptionsAmount
              } ${dataTypeSubtitle}${
                selectedOptionsAmount === 1 ? "" : "s"
              } selected`}
        </span>
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          ref={refs.setFloating}
          className="z-20 w-full"
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
          }}
        >
          <div className="flex flex-col gap-y-4 bg-white p-6 text-left align-middle shadow-xl">
            <Checkbox
              name="select-all"
              label={`Select all ${dataTypeSubtitle}s`}
              checked={isAllSelected}
              onChange={onSelectAll}
            />
            {data.map(({ name, label, disabled, type }, index) =>
              type === "checkbox" ? (
                <FormCheckbox
                  key={index}
                  name={name}
                  label={label}
                  disabled={disabled}
                />
              ) : (
                <Subtitle key={index}>{label}</Subtitle>
              )
            )}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}

function Subtitle({ children }) {
  return (
    <span className="text-sm font-semibold text-woodsmoke">{children}</span>
  )
}

export default MultiSelectPopover
