import { Fragment } from "react"
import { useFormikContext } from "formik"
import Card from "./Card"
import Chip from "./Chip"
import InformationSection from "./InformationSection"
import Subsection from "./Subsection"
import InfoTooltip from "./InfoTooltip"
import Tooltip from "./Tooltip"

const TOOLTIPS = {
  NATIONAL:
    "In the National Benchmarking Category. Companies are divided into two categories: Those with 50+ and those with 500+ employees. This is irrespective of Region or Industry.",
  REGION:
    "In the Region Benchmarking Category. Companies with 500+ employees are divided into 4 Regional Categories. This is irrespective of Industry. Please mouse over to see which states each region is made up of.",
  SIZE: "In the Size Benchmarking Category. Companies are divided into different categories based on the number employees. This is irrespective of Region or Industry.",
  INDUSTRY:
    "In the Industry Benchmarking Category. Companies are divided into different categories based on their industry. This is irrespective of Region. Please mouse over to see which SIC Codes each Industry category is made up of. If the category includes 500+ in the name it only contains companies with more than 500+ employees.",
  MERCER_SURVEY_DATA:
    "The Mercer 2022 data set does not include information for the PPO Max Out-of-Pocket, so we aren’t able to benchmark it against other datasets. We will still capture the data point for our own use.",
}

function BenchmarkingCategories({ categoriesByYear }) {
  const { setFieldValue, values } = useFormikContext()

  function handleBenchmarkSourceChange(year) {
    setFieldValue("benchmarkSource", year)
    setFieldValue("categories", categoriesByYear[year])
  }

  return (
    <InformationSection title="Benchmarking Information">
      {() => (
        <Card>
          <Subtitle>Benchmark Data Source</Subtitle>
          <div className="mb-10 flex flex-col gap-y-1.5">
            <InfoTooltip
              id="mercer-survey-data-tooltip"
              as="span"
              title="Mercer Survey Data "
              className="text-sm font-semibold text-shark"
              label={TOOLTIPS.MERCER_SURVEY_DATA}
            />
            <div className="space-x-1">
              {Object.keys(categoriesByYear).map(key => (
                <BenchmarkSourceChip
                    value={key}
                    currentValue={values.benchmarkSource}
                  onChange={handleBenchmarkSourceChange}
                />
              ))}
            </div>
          </div>

          <Subtitle>Benchmarking Categories</Subtitle>
          <div className="flex flex-wrap space-x-1">
            <SubsectionChip
              title="National"
              tooltip={TOOLTIPS.NATIONAL}
              tooltipId="national-tooltip"
              name="nationalOptions"
              categories={values.categories}
            />
            <SubsectionChip
              title="Region"
              tooltipId="region-tooltip"
              tooltip={TOOLTIPS.REGION}
              name="regionOptions"
              categories={values.categories}
            />
            <SubsectionChip
              title="Size"
              tooltipId="size-tooltip"
              tooltip={TOOLTIPS.SIZE}
              name="sizeOptions"
              categories={values.categories}
            />
            <SubsectionChip
              title="Industry"
              tooltipId="industry-tooltip"
              tooltip={TOOLTIPS.INDUSTRY}
              name="industryOptions"
              categories={values.categories}
            />
          </div>
        </Card>
      )}
    </InformationSection>
  )
}

function BenchmarkSourceChip({ value, currentValue, onChange }) {
  return (
    <Chip value={currentValue === value} onChange={() => onChange(value)}>
      {value}
    </Chip>
  )
}

function Subtitle({ children }) {
  return (
    <div className="mb-7 border-b-[0.031rem] border-gray-nurse pb-3 text-sm font-semibold text-shark">
      {children}
    </div>
  )
}

function SubsectionChip({
  name,
  title,
  tooltipId,
  tooltip,
  categories,
  ...props
}) {
  const { setFieldValue } = useFormikContext()

  return (
    <Subsection
      title={
        <InfoTooltip
          id={tooltipId}
          as="span"
          title={title}
          className="text-sm font-semibold text-tuatara"
          label={tooltip}
        />
      }
      containerClassName="flex-1"
    >
      <div className="flex flex-wrap gap-1.5">
        {categories[name].map((c, i) => {
          const id = `${tooltipId}-category-chip-${i}`
          return (
            <Fragment key={id}>
              <Chip
                id={id}
                showCheck={true}
                value={c.selected}
                onChange={(v) =>
                  setFieldValue(`categories.[${name}][${i}].selected`, v)
                }
                {...props}
              >
                {c.name}
              </Chip>
              <Tooltip id={id}>{c.description}</Tooltip>
            </Fragment>
          )
        })}
      </div>
    </Subsection>
  )
}

export default BenchmarkingCategories
