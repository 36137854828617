const VoluntaryIcon = (props) => (
  <svg
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_1484_15053"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="48"
      height="48"
    >
      <rect width="48" height="48" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1484_15053)">
      <path
        d="M6.75 39.25H17.25V20.75H6.75V39.25ZM18.75 39.25H29.25V8.75H18.75V39.25ZM30.75 39.25H41.25V24.75H30.75V39.25ZM5.25 40.75V19.25H17.25V7.25H30.75V23.25H42.75V40.75H5.25Z"
        fill="white"
      />
    </g>
  </svg>
)

export default VoluntaryIcon
