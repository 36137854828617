export const PLAN_TYPES = {
  PPO: "ppo",
  HMO: "hmo",
  HDHP: "hdhp",
}

export const PERCENTAGE_FIELDS = [
  "medicalPlanCost.monthlyTierContributionPercentage.individual",
  "medicalPlanCost.monthlyTierContributionPercentage.family",
  "medicalPlanCost.actuarial.actuarialValues",
  "medicalPlanDesign.coinsurance.coinsurance",
  "medicalPlanDesign.coinsurance.inPatientHospitalStay",
]

export const AUTOMATIC_INSIGHT_MANDATORY_FIELDS = [
  "medicalPlanCost.actuarial.actuarialValues",
  "medicalPlanCost.monthlyTierContribution.family",
  "medicalPlanCost.monthlyTierContribution.individual",
  "medicalPlanCost.monthlyTierContributionPercentage.family",
  "medicalPlanCost.monthlyTierContributionPercentage.individual",
  "medicalPlanCost.monthlyPremium.family",
  "medicalPlanCost.monthlyPremium.individual",
]

export const REPORT_PARTS = {
  demographic: {
    generationalBreakout: false,
    overview: false,
    migration: false,
    populationTable: false,
  },
  ppo: {
    monthlyTierContribution: false,
    monthlyTierContributionPercentage: false,
    monthlyPremium: false,
    actuarial: false,
    employerHsaContribution: false,
    inNetworkTierDeductible: false,
    inNetworkTierOutOfPocketMax: false,
    coinsurance: false,
    copayment: false,
    rxPlanDesign: false,
    tableMedicalPlanCost: false,
    tableMedicalPlanDesign: false,
    tableRxPlanDesign: false,
  },
  hmo: {
    monthlyTierContribution: false,
    monthlyTierContributionPercentage: false,
    monthlyPremium: false,
    actuarial: false,
    employerHsaContribution: false,
    inNetworkTierDeductible: false,
    inNetworkTierOutOfPocketMax: false,
    coinsurance: false,
    copayment: false,
    rxPlanDesign: false,
    tableMedicalPlanCost: false,
    tableMedicalPlanDesign: false,
    tableRxPlanDesign: false,
  },
  hdhp: {
    monthlyTierContribution: false,
    monthlyTierContributionPercentage: false,
    monthlyPremium: false,
    actuarial: false,
    employerHsaContribution: false,
    inNetworkTierDeductible: false,
    inNetworkTierOutOfPocketMax: false,
    coinsurance: false,
    copayment: false,
    rxPlanDesign: false,
    tableMedicalPlanCost: false,
    tableMedicalPlanDesign: false,
    tableRxPlanDesign: false,
  },
}
