import { forwardRef } from "react"
import Label from "./Label"
import InputContainer from "./InputContainer"
import ErrorInput from "./ErrorInput"
import { getButtonStyles } from "../utils/styles"

const Input = forwardRef(
  ({ name, label, error, classNames = {}, ...props }, ref) => (
    <InputContainer>
      {label ? <Label htmlFor={name}>{label}</Label> : null}
      <input
        ref={ref}
        id={name}
        name={name}
        className={getButtonStyles({
          className: classNames.input,
          showError: !!error,
        })}
        {...props}
      />
      <ErrorInput>{error}</ErrorInput>
    </InputContainer>
  )
)

export default Input
