import { twMerge } from "tailwind-merge"

function getButtonStyles({ className, showError = false } = {}) {
  return twMerge("text-sm disabled:bg-ebb disabled:border-none", className, {
    "border-mexican-red ring-1 ring-mexican-red": showError,
  })
}

function getLabelStyles(className) {
  return twMerge("mb-1 text-sm font-semibold text-shark", className)
}

function getErrorMessageStyles(className) {
  return twMerge("text-mexican-red", className)
}

export { getButtonStyles, getLabelStyles, getErrorMessageStyles }
