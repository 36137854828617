import clsx from "clsx"
import GenericButton from "./GenericButton"

function Button({ className, variant = "solid", ...props }) {
  return (
    <GenericButton
      className={clsx(className, {
        "border-transparent bg-allports text-white hover:bg-orient active:bg-astronaut-blue":
          variant === "solid",
        "border-natural-gray bg-white text-tuatara hover:bg-desert-storm active:bg-ebb disabled:bg-desert-storm disabled:text-dawn":
          variant === "outline",
      })}
      {...props}
    />
  )
}

export default Button
