import { datadogRum } from '@datadog/browser-rum';

export const datadogInit = () => datadogRum.init({
    env: process.env.REACT_APP_ENV,
    applicationId: process.env.REACT_APP_METRICS_APP_ID,
    clientToken: process.env.REACT_APP_METRICS_CLIENT_TOKEN,
    site: process.env.REACT_APP_METRICS_SITE,
    service: process.env.REACT_APP_METRICS_SERVICE,
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
});
