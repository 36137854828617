import { useEffect, useState } from "react"
import useMutation from "./useMutation"
import api from "../utils/api"
import { DEFAULT_BENCHMARKING_SOURCE } from "../constants/common"

function usePrepareCreateMedicalReport({
  numberOfEmployees,
  state,
  industryCode,
}) {
  const [categoriesByYear, setCategoriesByYear] = useState({
    [DEFAULT_BENCHMARKING_SOURCE]: {
      nationalOptions: [],
      regionOptions: [],
      industryOptions: [],
      sizeOptions: [],
    },
  })
  const [reports, setReports] = useState(null)

  const { isLoading, error, isError, data } = useMutation(
    () => api.post("/v1/prepareCreateMedicalReport"),
    null,
    {
      initOnMount: true,
    }
  )

  useEffect(() => {
    if (!data) {
      return
    }

    const newCategories = Object.entries(data.categories).reduce(
      (acc, [year, categoriesByYear]) => {
        acc[year] = Object.entries(categoriesByYear).reduce(
          (acc, [key, values]) => {
            function isValidValue(option, value) {
              return key === option && value
            }
            const selectionRules = []
            if (isValidValue("sizeOptions", numberOfEmployees)) {
              selectionRules.push({
                type: "range",
                value: +numberOfEmployees,
              })
            } else if (isValidValue("regionOptions", state)) {
              selectionRules.push({
                type: "where_in",
                value: state,
              })
            } else if (isValidValue("industryOptions", industryCode)) {
              selectionRules.push({
                type: "range",
                value: +String(industryCode).padEnd(4, "0"),
              })
            }
            acc[key] = values.map((category) => ({
              ...category,
              selected: isSelected(selectionRules, category),
            }))
            return acc
          },
          {}
        )
        return acc
      },
      {}
    )

    setReports(data.reports)
    setCategoriesByYear(newCategories)
  }, [numberOfEmployees, state, data, industryCode])

  return { isLoading, isError, categoriesByYear, reports, error }
}

function isSelected(matchingRules, category) {
  return !!matchingRules.some((matchingRule) =>
    category.suggestCriteria?.some((suggestCriteria) => {
      if (matchingRule.type !== suggestCriteria.type) {
        return false
      }

      switch (matchingRule.type) {
        case "range": {
          if (suggestCriteria.exclude?.includes(matchingRule.value)) {
            return false
          }
          let fromTest = true
          if (suggestCriteria.criteria.from) {
            fromTest = matchingRule.value >= suggestCriteria.criteria.from
          }
          let toTest = true
          if (suggestCriteria.criteria.to) {
            toTest = matchingRule.value <= suggestCriteria.criteria.to
          }
          return fromTest && toTest
        }
        case "where_in": {
          return suggestCriteria.criteria.values.includes(matchingRule.value)
        }
        default:
          return false
      }
    })
  )
}

export default usePrepareCreateMedicalReport
