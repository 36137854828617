import { Formik, Form } from "formik"
import FormInput from "./FormInput"
import ReportButton from "./ReportButton"

function VerificationTokenForm({ onVerify, error }) {
  async function handleSubmit(values, { setSubmitting }) {
    await onVerify(values.validationCode)
    setSubmitting(false)
  }

  return (
    <Formik
      initialValues={{ validationCode: "" }}
      onSubmit={handleSubmit}
      validate={(values) => {
        const errors = {}

        if (!values.validationCode) {
          errors.validationCode = "Required"
        }

        return errors
      }}
    >
      <Form className="space-y-6 rounded-lg bg-white p-10 shadow-lg">
        <div>
          <h3 className="text-center text-3xl font-black text-shark">
            Enter verification token
          </h3>
          <p className="mt-1.5 text-center">
            To view the report, introduce the corresponding verification token
          </p>
        </div>
        <FormInput
          className="rounded border-pumice"
          type="text"
          name="validationCode"
          error={error}
        />
        <ReportButton className="w-full" type="submit">
          VERIFY
        </ReportButton>
      </Form>
    </Formik>
  )
}

export default VerificationTokenForm
