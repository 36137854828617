import { Field } from "formik"
import Radio from "./Radio"

function FormRadio({ name, value, ...props }) {
  return (
    <Field name={name} value={value} type="radio">
      {({ field }) => <Radio {...props} {...field} />}
    </Field>
  )
}

export default FormRadio
